import React from "react"
import { Blurhash } from "react-blurhash"
import { isBlurhashValid } from "blurhash"
import { ValidatedBlurhashProps } from "./types"

const ValidatedBlurhash: React.FC<ValidatedBlurhashProps> = ({
  hash,
  width = "100%",
  height = "100%",
  resolutionX = 32,
  resolutionY = 32,
  punch = 1,
}) => {
  // Validate the hash before rendering
  const { result: isValid, errorReason } = isBlurhashValid(hash)

  if (!isValid) {
    console.error(`Blurhash validation failed: ${errorReason}`)
    return <>{errorReason}</>
  }

  return (
    <Blurhash
      hash={hash}
      width={width}
      height={height}
      resolutionX={resolutionX}
      resolutionY={resolutionY}
      punch={punch}
    />
  )
}

export default ValidatedBlurhash
