import { ReactElement } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"
// services
import { setBeforeLoginUrl } from "services/auth"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// constants
import { ENTRY_POINT, SETTINGS } from "constants/routes"

type Props = {
  children?: ReactElement
  isAllowed?: boolean
  redirectPath?: string
}

const ProtectedRoute = ({ children, isAllowed = true, redirectPath = ENTRY_POINT }: Props) => {
  const location = useLocation()
  const { authToken } = useAuthenticationContext()

  if (!authToken || !isAllowed) {
    !location.pathname.includes(SETTINGS) && setBeforeLoginUrl(location.pathname + location.search)
    return <Navigate to={redirectPath} replace />
  }

  return children ? children : <Outlet />
}

export default ProtectedRoute
