// constants
import { AUTH_TOKEN, BEFORE_LOGIN_URL, IS_MODAL_AUTH, NEED_ONBOARDING_FROM } from "constants/auth"
// utils
import LocalStorage from "utils/localStorage"

export const getToken = () => LocalStorage.get(AUTH_TOKEN)
export const setToken = (token: string) => LocalStorage.set(AUTH_TOKEN, token)
export const removeToken = () => LocalStorage.remove(AUTH_TOKEN)

export const getBeforeLoginUrl = () => LocalStorage.get(BEFORE_LOGIN_URL)
export const setBeforeLoginUrl = (beforeLoginUrl: string) => LocalStorage.set(BEFORE_LOGIN_URL, beforeLoginUrl)
export const removeBeforeLoginUrl = () => LocalStorage.remove(BEFORE_LOGIN_URL)

// Uses to understand whether the Auth doing by modal or not
export const getIsModalAuth = () => LocalStorage.get(IS_MODAL_AUTH)
export const setIsModalAuth = (isModalAuth: boolean) => LocalStorage.set(IS_MODAL_AUTH, isModalAuth)
export const removeIsModalAuth = () => LocalStorage.remove(IS_MODAL_AUTH)

// Uses to understand whether need onboarding modal to be show and when
export const getNeedOnboardingFrom = () => LocalStorage.get(NEED_ONBOARDING_FROM)
export const setNeedOnboardingFrom = (startTime: string) => LocalStorage.set(NEED_ONBOARDING_FROM, startTime)
export const removeNeedOnboardingFrom = () => LocalStorage.remove(NEED_ONBOARDING_FROM)
