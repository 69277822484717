import React, { useCallback, useMemo } from "react"
import { Dialog, Backdrop } from "@mui/material"
import { useModal } from "context/ModalContext/ModalContext"
// assets
import Onboarding from "assets/images/backgrounds/Onboarding3.svg"
// Pages
import EntryPoint from "pages/auth/EntryPoint"
import Login from "pages/auth/Login"
import Registration from "pages/auth/Registration"
import ForgotPassword from "pages/auth/ForgotPassword"
import ResetPassword from "pages/auth/ResetPassword"
import EmailConfirmation from "pages/auth/EmailConfirmation"
import CheckEmailConfirmation from "pages/auth/CheckEmailConfirmation"
import Welcome from "pages/auth/Welcome"
import PersonalData from "pages/auth/Onboarding/PersonalData"
import ProfessionalData from "pages/auth/Onboarding/ProfessionalData"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// constants
import { MODALS } from "constants/modals"
import OnboardingLayout from "../OnboardingLayout"
import Countdown from "pages/auth/Onboarding/Countdown"
// utils
import getHoursLeftFromCreation from "utils/getHoursLeftFromCreation"
import { useLocation } from "react-router-dom"
import { AUTH } from "constants/routes"

const GlobalModalHandler: React.FC = () => {
  const { currentModal, modalData, closeModal } = useModal()
  const { user } = useAuthenticationContext()
  const location = useLocation()

  const onboardingRequired = useMemo(() => {
    const hoursLeftFromUserCreation = getHoursLeftFromCreation(user.createdAt)

    return (
      hoursLeftFromUserCreation <= 0 &&
      (currentModal === MODALS.AUTH.ONBOARDING.COUNTDOWN ||
        currentModal === MODALS.AUTH.ONBOARDING.PERSONAL_DATA ||
        currentModal === MODALS.AUTH.ONBOARDING.PROFESSIONAL_DATA)
    )
  }, [user.createdAt, currentModal])

  const handleCloseModal = useCallback(() => {
    if (!onboardingRequired) {
      closeModal()
    }
  }, [closeModal, onboardingRequired])

  const renderModalContent = () => {
    switch (currentModal) {
      case MODALS.AUTH.ENTRY_POINT:
        return <EntryPoint isModalView {...modalData} />
      case MODALS.AUTH.LOGIN:
        return <Login isModalView {...modalData} />
      case MODALS.AUTH.REGISTRATION:
        return <Registration isModalView {...modalData} />
      case MODALS.AUTH.FORGOT_PASSWORD:
        return <ForgotPassword isModalView {...modalData} />
      case MODALS.AUTH.RESET_PASSWORD:
        return <ResetPassword isModalView {...modalData} />
      case MODALS.AUTH.EMAIL_CONFIRM:
        return <EmailConfirmation isModalView {...modalData} />
      case MODALS.AUTH.CHECK_EMAIL_CONFIRM:
        return <CheckEmailConfirmation isModalView {...modalData} />
      case MODALS.AUTH.WELCOME:
        return <Welcome isModalView {...modalData} />
      case MODALS.AUTH.ONBOARDING.COUNTDOWN:
        if (user.createdAt && !location.pathname.includes(AUTH)) {
          return (
            <OnboardingLayout
              withoutFrame
              showBackBtn={!onboardingRequired}
              isModalView
              rightBackgroundSrc={Onboarding}
            >
              <Countdown {...modalData} />
            </OnboardingLayout>
          )
        } else {
          closeModal()
          return <></>
        }
      case MODALS.AUTH.ONBOARDING.PERSONAL_DATA:
        return (
          <OnboardingLayout
            withoutFrame
            showBackBtn
            backBtnIconName="back"
            isModalView
            rightBackgroundSrc={Onboarding}
            backModal={MODALS.AUTH.ONBOARDING.COUNTDOWN}
          >
            <PersonalData isModalView {...modalData} />
          </OnboardingLayout>
        )
      case MODALS.AUTH.ONBOARDING.PROFESSIONAL_DATA:
        return (
          <OnboardingLayout
            withoutFrame
            backBtnIconName="back"
            showBackBtn
            isModalView
            rightBackgroundSrc={Onboarding}
            backModal={MODALS.AUTH.ONBOARDING.PERSONAL_DATA}
          >
            <ProfessionalData isModalView {...modalData} />
          </OnboardingLayout>
        )
      default:
        return <></>
    }
  }

  return (
    <Dialog
      open={!!currentModal}
      onClose={handleCloseModal}
      fullWidth
      maxWidth={false}
      components={{
        Backdrop,
      }}
      componentsProps={{
        backdrop: {
          style: {
            backgroundColor: "rgba(73, 73, 73, 0.6)",
          },
        },
      }}
      PaperProps={{
        style: {
          width: "80vw",
          height: "80vh",
        },
      }}
    >
      {renderModalContent()}
    </Dialog>
  )
}

export default GlobalModalHandler
