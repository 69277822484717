import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { MODALS } from "constants/modals"
import { RESET_PASSWORD } from "constants/routes"
import { OPEN_MODAL, RESET_PASSWORD_CODE } from "constants/searchParams"
import { getBeforeLoginUrl, getIsModalAuth, getNeedOnboardingFrom, setNeedOnboardingFrom } from "services/auth"
// context
import { useModal } from "context/ModalContext/ModalContext"
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// utils
import getHoursLeftFromCreation from "utils/getHoursLeftFromCreation"

const ONBOARDING_MODAL_TIMEOUT = 5 * 60 * 1000

const URLCheckRedirect: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { openModal, currentModal } = useModal()
  const { user } = useAuthenticationContext()

  const needOnboardingTimeFrom = getNeedOnboardingFrom()
  const hoursLeftFromUserCreation = getHoursLeftFromCreation(user.createdAt)

  useEffect(() => {
    if (!needOnboardingTimeFrom && user?.id && !user?.specialty) {
      setNeedOnboardingFrom(String(Date.now()))
    }

    // If the user is already onboarded (specialty exist), we don't need to show the onboarding modal
    if (!needOnboardingTimeFrom || !user?.id || !user?.confirmed || user?.specialty) {
      return
    }

    if (hoursLeftFromUserCreation <= 0) {
      openModal(MODALS.AUTH.ONBOARDING.COUNTDOWN)
      return
    }

    const timeNow = Date.now()
    const timeHasPassed = timeNow - +needOnboardingTimeFrom

    let timeoutId: NodeJS.Timeout

    const setupTimeout = (timeoutTimeLeft: number) => {
      setNeedOnboardingFrom(String(Date.now()))
      timeoutId = setTimeout(() => {
        if (!currentModal) openModal(MODALS.AUTH.ONBOARDING.COUNTDOWN)

        // After opening the modal, set a new timeout for the next occurrence
        setupTimeout(ONBOARDING_MODAL_TIMEOUT)
      }, timeoutTimeLeft)
    }

    if (timeHasPassed >= ONBOARDING_MODAL_TIMEOUT) {
      setNeedOnboardingFrom(String(timeNow))
      openModal(MODALS.AUTH.ONBOARDING.COUNTDOWN)

      // Immediately set a new timeout for the next occurrence
      setupTimeout(ONBOARDING_MODAL_TIMEOUT)
    } else {
      const timeoutTimeLeft = ONBOARDING_MODAL_TIMEOUT - timeHasPassed

      setupTimeout(timeoutTimeLeft)
    }

    return () => clearTimeout(timeoutId)
  }, [needOnboardingTimeFrom, user?.id])

  useEffect(() => {
    const isModalAuth = getIsModalAuth()
    const beforeLoginUrl = getBeforeLoginUrl()

    const isModalAuthFlow = isModalAuth === "true" && beforeLoginUrl

    if (isModalAuthFlow) {
      if (location.pathname === RESET_PASSWORD) {
        const searchParams = new URLSearchParams(location.search)
        searchParams.set(OPEN_MODAL, MODALS.AUTH.RESET_PASSWORD)
        const code = searchParams.get(RESET_PASSWORD_CODE)
        if (code) {
          searchParams.set(RESET_PASSWORD_CODE, code)
          navigate(`${beforeLoginUrl}?${searchParams.toString()}`)
        }
      }
    }
  }, [location, navigate])

  // Open modal from URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const modalToOpen = searchParams.get(OPEN_MODAL)

    if (modalToOpen) {
      openModal(modalToOpen)
      searchParams.delete(OPEN_MODAL)
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true })
    }
  }, [location.search, openModal, navigate])

  return null
}

export default URLCheckRedirect
