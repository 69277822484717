import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
// services
import { forgotPassword } from "api/auth"
import { setIsModalAuth } from "services/auth"

interface IForgotPassword {
  isModalView?: boolean
}

export const useForgotPassword = ({ isModalView }: IForgotPassword) => {
  const [error, setError] = useState()

  const {
    mutate: _forgotPassword,
    isLoading,
    isError,
    isSuccess,
  } = useMutation({
    mutationFn: forgotPassword,
    onSuccess: () => {
      if (isModalView) {
        setIsModalAuth(true)
      }
    },
    onError: (err: any) => {
      setError(err?.response?.data?.error?.message)
    },
  })

  return { forgotPassword: _forgotPassword, isLoading, isError, isSuccess, error }
}
