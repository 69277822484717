import React, { Component, ErrorInfo } from "react"
// pages
import Fallback from "pages/Fallback"

interface Props {
  children: React.ReactNode
}

interface State {
  hasError: boolean
  error: Error | null
  errorInfo: ErrorInfo | null
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Send report to Matomo
    // @ts-ignore
    window._paq?.push([
      "trackEvent",
      "Frontend error",
      error.stack,
      "localStorage: " + JSON.stringify({ ...localStorage }),
    ])

    console.error("Error caught by ErrorBoundary:", error)
    console.error("Component stack:", errorInfo.componentStack)
    this.setState({ errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return <Fallback />
    }

    return this.props.children
  }
}

export default ErrorBoundary
