import { ReactNode, useState } from "react"
// context
import PlayerContext from "./context"

type PlayerProviderProps = {
  children: ReactNode
}

const PlayerProvider = ({ children }: PlayerProviderProps) => {
  const [playerId, setPlayerId] = useState("")
  const [isMediaLoaded, setIsMediaLoaded] = useState(false)
  const [seekTo, setSeekTo] = useState(0)

  return (
    <PlayerContext.Provider value={{ playerId, setPlayerId, isMediaLoaded, setIsMediaLoaded, seekTo, setSeekTo }}>
      {children}
    </PlayerContext.Provider>
  )
}

export default PlayerProvider
