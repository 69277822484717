// styles
import { customStyled } from "styles/theme"
import { StartSImageProps, StartSRightSideProps } from "./types"
import { shouldForwardProp } from "styles/helpers"

export const SWrapper = customStyled("div")(({ theme: { breakpoints, palette } }) => ({
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  backgroundColor: palette.black.lacquer["950"],
  [breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
  },
}))

export const SLeftSide = customStyled("div")(({ theme: { breakpoints, spacing, palette } }) => ({
  zIndex: 1,
  display: "flex",
  background: palette.black.dark,
  width: "100%",
  borderTopLeftRadius: "24px",
  borderTopRightRadius: "24px",
  [breakpoints.up("sm")]: {
    padding: "40px 150px",
    height: "100vh",
    width: "50%",
    justifyContent: "center",
    overflowY: "auto",
    alignItems: "center",
  },

  [breakpoints.down("lg")]: {
    padding: "50px",
  },

  [breakpoints.down("sm")]: {
    padding: `${spacing(8)} ${spacing(5)} ${spacing(5)}`,
    height: "auto",
    flexGrow: 1,
  },

  [breakpoints.down("xs")]: {
    padding: `${spacing(7)} ${spacing(5)} ${spacing(5)}`,
    height: "auto",
  },
}))

export const SLeftSideInner = customStyled("div")(() => ({
  maxWidth: "438px",
  width: "100%",
  margin: "0 auto",
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
}))

export const SRightSide = customStyled("div", {
  shouldForwardProp: (prop) =>
    shouldForwardProp<StartSRightSideProps>(["withoutFrame", "commonRightSide", "focused"], prop),
})<StartSRightSideProps>(({ theme: { palette, breakpoints }, withoutFrame, commonRightSide, focused }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: palette.black.lacquer["950"],
  padding: "120px 94px",
  height: "100vh",
  position: "relative",
  ...(commonRightSide && { backgroundColor: palette.black.dark }),

  [breakpoints.up("sm")]: {
    flex: "0 0 50%",
    maxWidth: "50%",
  },

  ...(withoutFrame && { padding: 0 }),

  [breakpoints.down("lg")]: {
    padding: "120px 70px",

    ...(withoutFrame && { padding: 0 }),
  },

  [breakpoints.down("sm")]: {
    paddingTop: "72px",
    width: "100%",
    padding: "60px 40px 36px",
    maxHeight: "40vh",
    // transition: "all 350ms ease-in-out",

    ...(withoutFrame && { padding: 0 }),
    ...(focused && { maxHeight: "55px", padding: 0 }),
  },

  [breakpoints.down("xs")]: {
    ...(withoutFrame && { padding: 0 }),
  },

  [breakpoints.down("xxs")]: {
    ...(withoutFrame && { padding: 0 }),
  },
}))

export const SVideoWrapper = customStyled("div")(({ theme: { breakpoints } }) => ({
  maxWidth: "553px",
  width: "calc(100% - 50px)",
  zIndex: 1,
  // position: "absolute",
  borderRadius: "24px",
  overflow: "hidden",
  ":-webkit-mask-image": "-webkit-radial-gradient(white, black)",

  [breakpoints.down("sm")]: {
    maxWidth: "440px",
    width: "calc(100% - 32px)",
    top: "85px",
  },

  [breakpoints.down("xs")]: {
    maxWidth: "370px",
    width: "calc(100% - 32px)",
  },

  [breakpoints.down("xxs")]: {
    maxWidth: "calc(100% - 32px)",
  },
}))

export const SLogo = customStyled("div")(({ theme: { breakpoints, spacing } }) => ({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  zIndex: 1,
  top: spacing(10),
  right: spacing(10),

  [breakpoints.down("lg")]: {
    top: spacing(7),
    right: spacing(7),
  },

  [breakpoints.down("sm")]: {
    top: spacing(4),
    right: spacing(4),
  },
}))

export const SImage = customStyled("img")<StartSImageProps>(
  ({ theme: { breakpoints }, withoutFrame, commonRightSide, focused }) => ({
    width: "80%",
    zIndex: "0",
    height: "100%",
    objectFit: "contain",
    objectPosition: "center center",
    position: "absolute",
    [breakpoints.down("sm")]: {
      ...(focused && { opacity: 0 }),
    },
    ...(withoutFrame && { objectFit: "cover", width: "100%" }),
    ...(commonRightSide && {
      objectPosition: "center -120px",
      overflow: "inherit",
      margin: "90px -70px  -200px -90px",
      width: "135%",
      height: "160%",
      [breakpoints.down("sm")]: {
        margin: "10px -70px  0 -90px",
      },
    }),
  })
)
