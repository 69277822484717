import { Alert, Snackbar, Typography } from "@mui/material"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// hooks
import { useForgotPassword } from "./hooks/useForgotPassword"
import useToggleSnackbar from "hooks/useToggleSnackbar"
import { useToggleVisibility } from "hooks/useToggleVisibility"
// components
import OnboardingLayout from "shared/components/OnboardingLayout"
import Button from "shared/components/Button"
import { TextField } from "shared/components/input"
// constants
import { LOGIN } from "constants/routes"
import { colors } from "constants/colors"
// assets
import Onboarding2 from "assets/images/backgrounds/Onboarding2.svg"
// styles
import { STitle } from "../styles"
import { MODALS } from "constants/modals"

interface FormData {
  email: string
}

interface ForgotPasswordProps {
  isModalView?: boolean
}

const validator = (t: TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t("forgotPassword:inputFields:email:errors:format"))
      .required(t("forgotPassword:inputFields:email:errors:required")),
  })

const ForgotPassword = ({ isModalView }: ForgotPasswordProps) => {
  const { t } = useTranslation()
  const { forgotPassword, isLoading, isSuccess, isError, error } = useForgotPassword({ isModalView })
  const { open, handleClose } = useToggleSnackbar(isError)
  const { user } = useAuthenticationContext()
  const { isVisible: fieldFocused, handleShow } = useToggleVisibility()

  const { handleSubmit, formState, control } = useForm<FormData>({
    resolver: yupResolver(validator(t)),
    defaultValues: {
      email: user.email,
    },
    reValidateMode: "onChange",
  })

  const handleFormSubmit = ({ email }: FormData) => {
    forgotPassword({ email })
  }

  return (
    <OnboardingLayout
      focused={fieldFocused}
      backBtnIconName="back"
      logoColor={colors.green.dark}
      logoNameColor={colors.green.dark}
      showBackBtn
      withoutFrame
      rightBackgroundSrc={Onboarding2}
      backPath={LOGIN}
      isModalView={isModalView}
      backModal={MODALS.AUTH.LOGIN}
    >
      <STitle>{t("forgotPassword:title")}</STitle>
      <form
        style={{ height: "100%", flexGrow: 1, display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Controller
          name="email"
          control={control}
          render={({
            field,
            formState: {
              errors: { email },
            },
          }) => (
            <TextField
              onFocus={handleShow}
              type="email"
              inputProps={field}
              error={!!email?.message}
              helperText={email?.message}
              sx={{ mb: 4 }}
              placeholder={t("forgotPassword:inputFields:email:placeholder")}
            />
          )}
        />
        <Button
          sx={{ mt: "auto" }}
          type="submit"
          disabled={!!Object.keys(formState.errors).length || isLoading}
          color="secondary"
          fullWidth
          variant="contained"
        >
          {t("forgotPassword:submitButtonText")}
        </Button>
      </form>
      {isSuccess && (
        <Typography sx={{ maxWidth: 438 }} position="absolute" mt={-5} variant="h5" color="secondary.main">
          {t("forgotPassword:text")}
        </Typography>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={error}
        onClose={handleClose}
      >
        <Alert severity="error" sx={{ width: "100%" }} onClose={handleClose} closeText="Close">
          {error}
        </Alert>
      </Snackbar>
    </OnboardingLayout>
  )
}

export default ForgotPassword
