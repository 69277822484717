import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
// services
import { registration } from "api/auth"
import { setIsModalAuth } from "services/auth"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// constants
import { EMAIL_CONFIRM } from "constants/routes"
import { useModal } from "context/ModalContext/ModalContext"
import { MODALS } from "constants/modals"

interface RegistrationProps {
  isModalView?: boolean
}

const useRegistration = ({ isModalView = false }: RegistrationProps) => {
  const navigate = useNavigate()
  const { openModal } = useModal()
  const [error, setError] = useState()
  const { setUser } = useAuthenticationContext()

  const {
    mutate: toRegister,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: registration,
    onSuccess: ({ user }) => {
      console.log({
        id: user.id,
        confirmed: user.confirmed,
        name: user.user_profile?.userFirstName
          ? user.user_profile?.userFirstName + " " + user.user_profile?.userLastName
          : "",
        userName: user.username,
        email: user.email,
        // jobTitle: user.user_profile?.userJobTitle,
        // pictureUrl: user.user_profile?.userPicture?.url,
        // pictureId: user.user_profile?.userPicture?.id,
      })

      setUser({
        id: user.id,
        confirmed: user.confirmed,
        name: user.user_profile?.userFirstName
          ? user.user_profile?.userFirstName + " " + user.user_profile?.userLastName
          : "",
        userName: user.username,
        email: user.email,
        // jobTitle: user.user_profile?.userJobTitle,
        // pictureUrl: user.user_profile?.userPicture?.url,
        // pictureId: user.user_profile?.userPicture?.id,
      })

      if (isModalView) {
        setIsModalAuth(true)
        openModal(MODALS.AUTH.EMAIL_CONFIRM)
      } else {
        navigate(EMAIL_CONFIRM, { replace: true })
      }
    },
    onError: (err: any) => {
      setError(err?.response?.data?.error?.message)
    },
  })

  return { toRegister, isLoading, isError, error }
}

export default useRegistration
