import { Alert, Snackbar } from "@mui/material"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useCallback, useEffect, useMemo } from "react"
import * as yup from "yup"
import React from "react"
import InputMask from "react-input-mask"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// hooks
import { useOnboarding } from "hooks/useOnboarding"
import useToggleSnackbar from "hooks/useToggleSnackbar"
import { useSpecialties } from "pages/hooks/useSpecialties"
import { useModal } from "context/ModalContext/ModalContext"
import { useNavigate } from "react-router-dom"
// components
import Button from "shared/components/Button"
import { Autocomplete, TextField } from "shared/components/input"
import Tag from "shared/components/Tag"
// constants
import { PROFILE_IMAGE } from "constants/routes"
import { EFN_MUSK, JOB_POSITIONS, JOB_POSITIONS_VALUES } from "constants/auth"
// styles
import { SText, STitle } from "../../styles"
// types
import { Option } from "shared/components/input/Autocomplete/types"
import { Language } from "types/i18n"
// utils
import { removeIsModalAuth, removeNeedOnboardingFrom } from "services/auth"
import { validateEFN } from "utils/string"

interface FormData {
  userJobPosition: Option
  specialty: Option<number | string>
  userJobTitle: string
  userEFN: string
  userPartnercode: string
}

interface ProfessionalDataProps {
  isModalView?: boolean
}

const validator = (t: TFunction) =>
  yup.object().shape({
    userJobPosition: yup
      .object()
      .required(t("onboarding:professionalData:inputFields:userJobPosition:errors:required"))
      .nullable(),
    specialty: yup.object().required(t("onboarding:professionalData:inputFields:specialty:errors:required")).nullable(),
    // userJobTitle: yup
    //   .string()
    //   .when("userJobPosition.value", (_userJobTitle, schema) =>
    //     _userJobTitle === JOB_POSITIONS_VALUES.Student
    //       ? schema
    //       : schema.required(t("settings:profile:inputFields:userJobTitle:errors:required"))
    //   ),
    userEFN: yup
      .string()
      .required(t("settings:profile:inputFields:userEFN:errors:required"))
      .transform((value) => (value ? value.replace(/\s+/g, "") : ""))
      .test("is-valid-efn", t("onboarding:professionalData:inputFields:userEFN:errors:invalid"), (value = "") => {
        if (!/^\d+$/.test(value)) {
          return new yup.ValidationError(
            t("onboarding:professionalData:inputFields:userEFN:errors:digits"),
            null,
            "userEFN"
          )
        }
        if (value.length !== 15) {
          return new yup.ValidationError(
            t("onboarding:professionalData:inputFields:userEFN:errors:digitsAmount"),
            null,
            "userEFN"
          )
        }
        if (!validateEFN(value)) {
          return new yup.ValidationError(
            t("onboarding:professionalData:inputFields:userEFN:errors:invalid"),
            null,
            "userEFN"
          )
        }
        return true
      }),
  })

// Define TextMaskCustom component
interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void
  name: string
  mask: string
  onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, TextMaskCustomProps>(function TextMaskCustom(props) {
  const { inputRef, ...other } = props
  return (
    <InputMask
      {...other}
      inputRef={(inputElement: HTMLInputElement) => {
        if (inputElement && typeof inputRef === "function") {
          inputRef(inputElement)
        }
      }}
    />
  )
})

const ProfessionalData = ({ isModalView }: ProfessionalDataProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { closeModal } = useModal()

  const onSuccessfulSubmit = useCallback(() => {
    if (isModalView) {
      removeNeedOnboardingFrom()
      removeIsModalAuth()
      closeModal()
    } else {
      navigate(PROFILE_IMAGE)
    }
  }, [isModalView, closeModal, navigate])

  const { updateUserData, isLoading, isError, error } = useOnboarding(onSuccessfulSubmit)
  const { open, handleClose } = useToggleSnackbar(isError)
  const {
    user: {
      userProfileId,
      userJobPosition,
      specialty,
      userJobTitle,
      userEFN,
      // TODO: hidden according to MN-361
      // userPartnercode
    },
  } = useAuthenticationContext()

  const { specialties = [{ id: -1, name: "" }], isLoading: isSpecialtiesLoading } = useSpecialties(
    i18n.language.slice(0, 2) as Language
  )
  const _specialties = useMemo(() => specialties.map((s) => ({ value: s.id, label: s.name })), [specialties])
  const jobPositions = useMemo(() => JOB_POSITIONS.get(i18n.language.slice(0, 2) as Language)!, [i18n.language])

  const { handleSubmit, formState, control, setValue, watch, unregister } = useForm<FormData>({
    resolver: yupResolver(validator(t)),
    defaultValues: {
      userJobPosition: jobPositions.find((j) => j.value === userJobPosition),
      specialty: { value: 0, label: "" },
      userJobTitle: userJobTitle || "",
      userEFN: userEFN || "",
      // TODO: hidden according to MN-361
      // userPartnercode: userPartnercode || "",
    },
    reValidateMode: "onChange",
  })

  const handleFormSubmit = ({
    userJobPosition,
    specialty,
    userJobTitle,
    // TODO: hidden according to MN-361
    // userPartnercode,
    userEFN,
  }: FormData) => {
    updateUserData({
      userProfileId,
      userJobPosition: userJobPosition.value,
      specialty: specialty.value,
      userJobTitle: userJobTitle || "",
      userEFN: userEFN?.replace(/\s+/g, "").trim(),
      // TODO: hidden according to MN-361
      // userPartnercode,
    })
  }

  useEffect(() => {
    setValue("specialty", _specialties.find((s) => s.label === specialty)!)
  }, [_specialties, setValue, specialty])

  const watchedUserJobPosition = watch("userJobPosition")

  useEffect(() => {
    if (watchedUserJobPosition?.value === JOB_POSITIONS_VALUES.Student) {
      unregister("userJobTitle", {
        keepValue: true,
        keepError: false,
      })
    }
  }, [watchedUserJobPosition, unregister])

  return (
    <div>
      <Tag sx={{ mb: 4 }} variant="lightgreen">
        {t("onboarding:professionalData:step", { current: 2, from: isModalView ? 2 : 3 })}
      </Tag>
      <STitle>{t("onboarding:professionalData:title")}</STitle>
      <SText variant="h5">{t("onboarding:professionalData:text")}</SText>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Controller
          name="userJobPosition"
          control={control}
          render={({
            field: { onChange, ref, ...field },
            formState: {
              errors: { userJobPosition },
            },
          }) => (
            <Autocomplete
              {...field}
              onChange={(_, data) => onChange(data)}
              sx={{ mb: 4 }}
              textFieldProps={{
                ...field,
                error: !!userJobPosition?.message,
                helperText: userJobPosition?.message,
                label: t("onboarding:professionalData:inputFields:userJobPosition:placeholder"),
                inputRef: ref,
              }}
              options={jobPositions}
            />
          )}
        />
        <Controller
          name="specialty"
          control={control}
          render={({
            field: { onChange, ref, ...field },
            formState: {
              errors: { specialty },
            },
          }) => (
            <Autocomplete
              {...field}
              loading={isSpecialtiesLoading}
              loadingText={t("shared:loading")}
              onChange={(_, data) => onChange(data)}
              sx={{ mb: 4 }}
              textFieldProps={{
                ...field,
                error: !!specialty?.message,
                helperText: specialty?.message,
                label: t("onboarding:professionalData:inputFields:specialty:placeholder"),
                inputRef: ref,
              }}
              options={_specialties.length > 1 ? _specialties : []}
            />
          )}
        />
        {watchedUserJobPosition?.value !== JOB_POSITIONS_VALUES.Student && (
          <Controller
            name="userJobTitle"
            control={control}
            render={({
              field,
              formState: {
                errors: { userJobTitle },
              },
            }) => (
              <TextField
                type="text"
                inputProps={field}
                error={!!userJobTitle?.message}
                helperText={userJobTitle?.message}
                sx={{ mb: 4 }}
                placeholder={t("onboarding:professionalData:inputFields:userJobTitle:placeholder")}
              />
            )}
          />
        )}
        <Controller
          name="userEFN"
          control={control}
          render={({
            field,
            formState: {
              errors: { userEFN },
            },
          }) => (
            <TextField
              {...field}
              InputProps={{
                inputComponent: TextMaskCustom as any,
              }}
              inputProps={{
                mask: EFN_MUSK,
                onBlur: field.onBlur,
                onChange: field.onChange,
              }}
              error={!!userEFN?.message}
              helperText={userEFN?.message}
              sx={{ mb: 4 }}
              placeholder={t("onboarding:professionalData:inputFields:userEFN:placeholder")}
            />
          )}
        />
        {/*
         // TODO: hidden according to MN-361
        <Controller
          name="userPartnercode"
          control={control}
          render={({
            field,
            formState: {
              errors: { userPartnercode },
            },
          }) => (
            <TextField
              type="text"
              inputProps={field}
              error={!!userPartnercode?.message}
              helperText={userPartnercode?.message}
              sx={{ mb: 4 }}
              placeholder={t("onboarding:professionalData:inputFields:userPartnercode:placeholder")}
            />
          )}
        /> */}
        <Button
          type="submit"
          disabled={!!Object.keys(formState.errors).length || isLoading}
          color="secondary"
          fullWidth
          variant="contained"
        >
          {t("onboarding:professionalData:submitButtonText")}
        </Button>
      </form>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={error}
        onClose={handleClose}
      >
        <Alert severity="error" sx={{ width: "100%" }} onClose={handleClose} closeText="Close">
          {error}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default ProfessionalData
