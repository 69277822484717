import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
// types
import { EpisodeType } from "pages/Course/hooks/useCourse/types"
import { Language } from "types/i18n"

type VideoType = Pick<EpisodeType, "kalturaVideoId" | "localizations"> & { locale: "en" | "de-DE" }

const VIDEOS: VideoType[] = [
  {
    kalturaVideoId: "1_o545s6dp",
    locale: "en",
    localizations: [{ attributes: { locale: "de-DE", kalturaVideoId: "1_tkf4au6x" }, id: 1 }],
  },
  {
    kalturaVideoId: "1_tkf4au6x",
    locale: "de-DE",
    localizations: [{ attributes: { locale: "en", kalturaVideoId: "1_o545s6dp" }, id: 2 }],
  },
]

const VIDEO_LANGUAGES = new Map<Language, { videoLanguage: string; localizationKey: string }[]>()

VIDEO_LANGUAGES.set("en", [
  { videoLanguage: "English", localizationKey: "en" },
  { videoLanguage: "German", localizationKey: "de-DE" },
])

VIDEO_LANGUAGES.set("de", [
  { videoLanguage: "Englisch", localizationKey: "en" },
  { videoLanguage: "Deutsch", localizationKey: "de-DE" },
])

const useWelcomePage = () => {
  const { i18n } = useTranslation()
  const [currentVideo, setCurrentVideo] = useState<VideoType>(VIDEOS[0])

  const videoLanguages = useMemo(
    () => VIDEO_LANGUAGES.get(i18n.language.slice(0, 2) as Language)!,
    [VIDEO_LANGUAGES, i18n.language]
  )

  useEffect(() => {
    VIDEOS.forEach((video) => {
      if (video.locale.includes(i18n.language)) {
        setCurrentVideo(video)
      }
    })
  }, [VIDEOS, i18n.language])

  return { currentVideo, videoLanguages }
}

export default useWelcomePage
