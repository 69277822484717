import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Checkbox, FormControl, FormControlLabel, FormHelperText, Snackbar } from "@mui/material"
import { TFunction } from "i18next"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import { useEffect } from "react"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// hooks
import useToggleSnackbar from "hooks/useToggleSnackbar"
import useRegistration from "./hooks/useRegistration"
// components
import Button from "shared/components/Button"
import { TextField } from "shared/components/input"
import OnboardingLayout from "shared/components/OnboardingLayout"
import SimpleLink from "shared/components/SimpleLink"
// constants
import { ENTRY_POINT } from "constants/routes"
import { MODALS } from "constants/modals"
// assets
import Onboarding from "assets/images/backgrounds/Onboarding1.svg"
// utils
import { passwordValidator } from "utils/validators"
// style
import { SText, STitle } from "../styles"

interface FormData {
  email: string
  password: string
  passwordConfirmation: string
  acceptedTermsAndConditions: boolean
  acceptedTrackingBehavior: boolean
  acceptedEmailNewsletter: boolean
}

interface RegistrationProps {
  isModalView?: boolean
}

const validator = (t: TFunction) =>
  yup
    .object()
    .shape({
      email: yup
        .string()
        .email(t("registration:inputFields:email:errors:format"))
        .required(t("registration:inputFields:email:errors:required")),
      passwordConfirmation: yup
        .string()
        .required(t("resetPassword:inputFields:password:errors:required"))
        .min(8, t("resetPassword:inputFields:password:errors:min"))
        .oneOf([yup.ref("password"), null], t("resetPassword:inputFields:passwordConfirmation:errors:match")),
      acceptedTermsAndConditions: yup
        .bool()
        .oneOf([true], t("registration:inputFields:acceptTermsAndConditions:errors:required")),
      acceptedTrackingBehavior: yup
        .bool()
        .oneOf([true], t("registration:inputFields:acceptedTrackingBehavior:errors:required")),
    })
    .concat(passwordValidator(t))

const Registration = ({ isModalView }: RegistrationProps) => {
  const { t } = useTranslation()
  const { toRegister, isLoading, isError, error } = useRegistration({ isModalView })
  const { open, handleClose } = useToggleSnackbar(isError)
  const { user } = useAuthenticationContext()

  const { handleSubmit, formState, control, watch, trigger } = useForm<FormData>({
    resolver: yupResolver(validator(t)),
    defaultValues: {
      email: user.email,
      password: "",
      passwordConfirmation: "",
      acceptedTermsAndConditions: false,
      acceptedEmailNewsletter: false,
      acceptedTrackingBehavior: false,
    },
    reValidateMode: "onChange",
  })

  const watchedEmail = watch("email")
  const watchedPass = watch("password")
  const watchedConfirmPass = watch("passwordConfirmation")
  const watchedAcceptedTerms = watch("acceptedTermsAndConditions")
  const watchedTrackingBehavior = watch("acceptedTrackingBehavior")

  useEffect(() => {
    const passwordSchema = passwordValidator(t)

    if (!watchedPass || !passwordSchema.isValidSync({ password: watchedPass })) return

    trigger("passwordConfirmation")
  }, [watchedPass, watchedConfirmPass, trigger, t])

  const handleFormSubmit = ({
    email,
    password,
    acceptedTermsAndConditions,
    acceptedEmailNewsletter,
    acceptedTrackingBehavior,
  }: FormData) => {
    toRegister({ email, password, acceptedTermsAndConditions, acceptedEmailNewsletter, acceptedTrackingBehavior })
  }

  const isFormValid =
    watchedEmail &&
    watchedPass &&
    watchedConfirmPass &&
    watchedAcceptedTerms &&
    watchedTrackingBehavior &&
    !Object.keys(formState.errors).length

  return (
    <OnboardingLayout
      focused={true}
      showBackBtn
      rightBackgroundSrc={Onboarding}
      backBtnIconName="back"
      backPath={ENTRY_POINT}
      isModalView={isModalView}
      backModal={MODALS.AUTH.ENTRY_POINT}
    >
      <STitle>{t("registration:title")}</STitle>
      <SText variant="h5">{t("registration:text")}</SText>
      <form
        style={{ height: "100%", flexGrow: 1, display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Controller
          name="email"
          control={control}
          render={({
            field,
            formState: {
              errors: { email },
            },
          }) => (
            <TextField
              type="email"
              inputProps={field}
              error={!!email?.message}
              helperText={email?.message}
              sx={{ mb: 4 }}
              placeholder={t("registration:inputFields:email:placeholder")}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({
            field,
            formState: {
              errors: { password },
            },
          }) => (
            <TextField
              onCopy={(e) => e.preventDefault()}
              type="password"
              inputProps={field}
              error={!!password?.message}
              helperText={password?.message}
              infoText={t("registration:inputFields:password:infoText")}
              sx={{ mb: 4 }}
              placeholder={t("registration:inputFields:password:placeholder")}
            />
          )}
        />
        <Controller
          name="passwordConfirmation"
          control={control}
          render={({
            field,
            formState: {
              errors: { passwordConfirmation },
            },
          }) => (
            <TextField
              onPaste={(e) => e.preventDefault()}
              type="password"
              inputProps={field}
              error={!!passwordConfirmation?.message}
              helperText={passwordConfirmation?.message}
              sx={{ mb: 4 }}
              placeholder={t("resetPassword:inputFields:passwordConfirmation:placeholder")}
              infoText={t("resetPassword:inputFields:passwordConfirmation:infoText")}
            />
          )}
        />
        <FormControl sx={{ mb: 5 }} error={!!formState.errors.acceptedTermsAndConditions?.message}>
          <FormControlLabel
            control={
              <Controller
                name="acceptedTermsAndConditions"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    color="secondary"
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    sx={{ alignSelf: "flex-start", paddingY: 0 }}
                  />
                )}
              />
            }
            label={
              <div>
                <SimpleLink
                  href="https://medudy.com/allgemeine-geschaeftsbedingungen"
                  target="_blank"
                  sx={[
                    ({ palette: { green } }) => ({
                      color: green.hyperpop[400],
                      "&:hover": {
                        color: green.hyperpop[600],
                      },
                    }),
                  ]}
                >
                  {t("registration:inputFields:acceptTermsAndConditions:placeholder:button1")}
                </SimpleLink>
                {t("registration:inputFields:acceptTermsAndConditions:placeholder:text1")}
                <SimpleLink
                  href="https://www.medudy.com/datenschutz"
                  target="_blank"
                  sx={[
                    ({ palette: { green } }) => ({
                      color: green.hyperpop[400],
                      "&:hover": {
                        color: green.hyperpop[600],
                      },
                    }),
                  ]}
                >
                  {t("registration:inputFields:acceptTermsAndConditions:placeholder:button2")}
                </SimpleLink>
                {t("registration:inputFields:acceptTermsAndConditions:placeholder:text2")}
              </div>
            }
          />
          <FormHelperText sx={{ mt: "-8px", mb: 0 }}>
            {formState.errors.acceptedTermsAndConditions?.message}
          </FormHelperText>
        </FormControl>
        <FormControl sx={{ mb: 5 }} error={!!formState.errors.acceptedTrackingBehavior?.message}>
          <FormControlLabel
            control={
              <Controller
                name="acceptedTrackingBehavior"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    color="secondary"
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    sx={{ alignSelf: "flex-start", paddingY: 0 }}
                  />
                )}
              />
            }
            label={
              <div>
                {t("registration:inputFields:acceptedTrackingBehavior:placeholder:text1")}
                <SimpleLink
                  href="https://www.medudy.com/datenschutz"
                  target="_blank"
                  sx={[
                    ({ palette: { green } }) => ({
                      color: green.hyperpop[400],
                      "&:hover": {
                        color: green.hyperpop[600],
                      },
                    }),
                  ]}
                >
                  {t("registration:inputFields:acceptedTrackingBehavior:placeholder:button1")}
                </SimpleLink>
              </div>
            }
          />
          <FormHelperText sx={{ mt: "-8px", mb: 0 }}>
            {formState.errors.acceptedTrackingBehavior?.message}
          </FormHelperText>
        </FormControl>
        <FormControl sx={{ mb: 6 }} error={!!formState.errors.acceptedEmailNewsletter?.message}>
          <FormControlLabel
            control={
              <Controller
                name="acceptedEmailNewsletter"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    color="secondary"
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    sx={{ alignSelf: "flex-start", paddingY: 0 }}
                  />
                )}
              />
            }
            label={t("registration:inputFields:acceptedEmailNewsletter:placeholder")}
          />
        </FormControl>
        <Button
          sx={{ mt: "auto" }}
          type="submit"
          disabled={!isFormValid || isLoading}
          color="secondary"
          fullWidth
          variant="contained"
        >
          {t("registration:submitButtonText")}
        </Button>
      </form>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={error}
        onClose={handleClose}
      >
        <Alert severity="error" sx={{ width: "100%" }} onClose={handleClose} closeText="Close">
          {error}
        </Alert>
      </Snackbar>
    </OnboardingLayout>
  )
}

export default Registration
