import { lazy, Suspense } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
// hooks
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
// constants
import { navigationLinksProfile, navigationLinksPolicy } from "pages/layouts/Settings/NavigationMenu/constants"
// routes
import {
  AUTH,
  COURSE_SHARE,
  COURSE_EPISODE_SHARE,
  COURSE_PUBLIC,
  COURSE_PRIVATE,
  COURSE,
  DETAIL,
  EMAIL_CONFIRM,
  CHECK_EMAIL_CONFIRM,
  ENTRY_POINT,
  PURCHASED,
  FAVOURITES,
  HOME,
  LOGIN,
  NOTIFICATION,
  REGISTRATION,
  SEARCH,
  SETTINGS,
  SPEAKER,
  SPEAKER_COURSES,
  STORYBOOK,
  USER,
  WATCHED,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  WELCOME,
  ALL_COURSES,
  ALL_EPISODES,
  ALL_SHORT_UNITS,
  ALL_INTROS,
  CHANNEL,
  TEST,
  VERIFICATION_ERROR,
} from "../constants/routes"
// providers
import AuthenticationProvider from "context/Authentication/AuthenticationProvider"
import { ModalProvider } from "context/ModalContext/ModalContext"
// router for v6 react router and redux
import { HistoryRouter as Router } from "redux-first-history/rr6"
// history
import { history } from "../store"
// components
import GlobalLoading from "shared/components/GlobalLoading"
import ProtectedRoute from "./components/ProtectedRoute"
import Fallback from "pages/Fallback"
import Channel from "pages/Channel"

// pages lazy
const Home = lazy(() => import("pages/Home"))
const Search = lazy(() => import("pages/Search"))
const Notification = lazy(() => import("pages/Notifications"))
const User = lazy(() => import("pages/User"))
const StoryBook = lazy(() => import("pages/StoryBook"))
const Detail = lazy(() => import("pages/Detail"))
const Course = lazy(() => import("pages/Course"))
const CourseShare = lazy(() => import("pages/Course/CourseShare"))
const CoursePublic = lazy(() => import("pages/CoursePublic"))
const CoursePrivate = lazy(() => import("pages/CoursePrivate"))
const Purchased = lazy(() => import("pages/Purchased"))
const Test = lazy(() => import("pages/Test"))
const Favourites = lazy(() => import("pages/Favourites"))
const Settings = lazy(() => import("pages/layouts/Settings"))
const Speaker = lazy(() => import("pages/Speaker"))
const SpeakerCourses = lazy(() => import("pages/SpeakerCourses"))
const Watched = lazy(() => import("pages/Watched"))
const AllCourses = lazy(() => import("pages/AllCourses"))
const AllEpisodes = lazy(() => import("pages/AllEpisodes"))
const AllShortUnits = lazy(() => import("pages/AllShortUnits"))
const AllIntros = lazy(() => import("pages/AllIntros"))
// auth
const Auth = lazy(() => import("./components/Auth"))
const EntryPoint = lazy(() => import("pages/auth/EntryPoint"))
const Login = lazy(() => import("pages/auth/Login"))
const Registration = lazy(() => import("pages/auth/Registration"))
const EmailConfirmation = lazy(() => import("pages/auth/EmailConfirmation"))
const CheckEmailConfirmation = lazy(() => import("pages/auth/CheckEmailConfirmation"))
const ForgotPassword = lazy(() => import("pages/auth/ForgotPassword"))
const ResetPassword = lazy(() => import("pages/auth/ResetPassword"))
const Welcome = lazy(() => import("pages/auth/Welcome"))
// onboarding
// const OnboardingRoute = lazy(() => import("./components/OnboardingRoute"))
// const PersonalData = lazy(() => import("pages/auth/Onboarding/PersonalData"))
// const ProfessionalData = lazy(() => import("pages/auth/Onboarding/ProfessionalData"))
// const ProfileImage = lazy(() => import("pages/auth/Onboarding/ProfileImage"))
// components
import URLCheckRedirect from "./components/URLCheckRedirect"

const AppRoutes = () => {
  const { isSmallDesktop } = useMUIBreakpoints()

  return (
    <Router history={history}>
      <AuthenticationProvider>
        <ModalProvider>
          <Suspense fallback={<GlobalLoading />}>
            <URLCheckRedirect />
            <Routes>
              <Route
                path={VERIFICATION_ERROR}
                element={
                  <Fallback
                    titleKey="fallback:titleVerificationError"
                    textKey="fallback:descriptionVerificationError"
                    actionKey="fallback:actionTextVerificationError"
                    buttonTextKey="fallback:buttonTextVerificationError"
                    navPath="https://www.medudy.com/kontakt"
                  />
                }
              />
              <Route
                path={HOME}
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route
                path={SEARCH}
                element={
                  <ProtectedRoute>
                    <Search />
                  </ProtectedRoute>
                }
              />
              <Route
                path={NOTIFICATION}
                element={
                  <ProtectedRoute>
                    <Notification />
                  </ProtectedRoute>
                }
              />
              <Route
                path={USER}
                element={
                  <ProtectedRoute>
                    <User />
                  </ProtectedRoute>
                }
              />
              <Route
                path={STORYBOOK}
                element={
                  <ProtectedRoute>
                    <StoryBook />
                  </ProtectedRoute>
                }
              />
              <Route
                path={DETAIL}
                element={
                  <ProtectedRoute>
                    <Detail />
                  </ProtectedRoute>
                }
              />
              <Route path={COURSE_SHARE} element={<CourseShare />} />
              <Route path={COURSE_EPISODE_SHARE} element={<CourseShare />} />
              <Route path={COURSE_PUBLIC} element={<CoursePublic />} />
              <Route path={COURSE_PRIVATE} element={<CoursePrivate />} />
              <Route
                path={COURSE}
                element={
                  <ProtectedRoute>
                    <Course />
                  </ProtectedRoute>
                }
              />
              <Route
                path={PURCHASED}
                element={
                  <ProtectedRoute>
                    <Purchased />
                  </ProtectedRoute>
                }
              />
              <Route
                path={TEST}
                element={
                  <ProtectedRoute>
                    <Test />
                  </ProtectedRoute>
                }
              />
              <Route
                path={TEST}
                element={
                  <ProtectedRoute>
                    <Test />
                  </ProtectedRoute>
                }
              />
              <Route
                path={FAVOURITES}
                element={
                  <ProtectedRoute>
                    <Favourites />
                  </ProtectedRoute>
                }
              />
              <Route
                path={SETTINGS}
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              >
                {navigationLinksProfile.map(({ links }) =>
                  links.map(({ path, Page }) => <Route key={path} path={path} element={<Page />} />)
                )}
                {!isSmallDesktop && (
                  <Route
                    path={SETTINGS}
                    element={<Navigate to={navigationLinksProfile[0].links[0].path} replace={true} />}
                  />
                )}
              </Route>
              <Route path={SETTINGS} element={<Settings />}>
                {navigationLinksPolicy.map(({ links }) =>
                  links.map(({ path, Page }) => <Route key={path} path={path} element={<Page />} />)
                )}
              </Route>
              <Route
                path={SPEAKER}
                element={
                  <ProtectedRoute>
                    <Speaker />
                  </ProtectedRoute>
                }
              />
              <Route
                path={SPEAKER_COURSES}
                element={
                  <ProtectedRoute>
                    <SpeakerCourses />
                  </ProtectedRoute>
                }
              />
              <Route
                path={WATCHED}
                element={
                  <ProtectedRoute>
                    <Watched />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ALL_COURSES}
                element={
                  <ProtectedRoute>
                    <AllCourses />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ALL_EPISODES}
                element={
                  <ProtectedRoute>
                    <AllEpisodes />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ALL_SHORT_UNITS}
                element={
                  <ProtectedRoute>
                    <AllShortUnits />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ALL_EPISODES}
                element={
                  <ProtectedRoute>
                    <AllEpisodes />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ALL_INTROS}
                element={
                  <ProtectedRoute>
                    <AllIntros />
                  </ProtectedRoute>
                }
              />
              <Route path={AUTH} element={<Auth />}>
                <Route path={ENTRY_POINT} element={<EntryPoint />} />
                <Route path={LOGIN} element={<Login />} />
                <Route path={REGISTRATION} element={<Registration />} />
                <Route path={EMAIL_CONFIRM} element={<EmailConfirmation />} />
                <Route path={CHECK_EMAIL_CONFIRM} element={<CheckEmailConfirmation />} />
                <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
                <Route path={RESET_PASSWORD} element={<ResetPassword />} />
              </Route>
              <Route path={WELCOME} element={<Welcome />} />
              <Route path={CHANNEL} element={<Channel />} />
              {/* <Route
                path={ONBOARDING}
                element={
                  <ProtectedRoute>
                    <OnboardingRoute />
                  </ProtectedRoute>
                }
              >
                <Route index element={<PersonalData />} />
                <Route path={PERSONAL_DATA} element={<PersonalData />} />
                <Route path={PROFESSIONAL_DATA} element={<ProfessionalData />} />
                <Route path={PROFILE_IMAGE} element={<ProfileImage />} />
              </Route> */}
              <Route path="*" element={<Navigate to={ENTRY_POINT} replace={true} />} />
            </Routes>
          </Suspense>
        </ModalProvider>
      </AuthenticationProvider>
    </Router>
  )
}

export default AppRoutes
