import { Option } from "shared/components/input/Autocomplete/types"
import { Language } from "types/i18n"

export const AUTH_TOKEN = "medudyAuthToken"
export const BEFORE_LOGIN_URL = "beforeLoginUrl"
export const IS_MODAL_AUTH = "isModalAuth"
export const NEED_ONBOARDING_FROM = "needOnboardingFrom"

export const GENDER_OPTIONS = new Map<Language, Option[]>()

GENDER_OPTIONS.set("en", [
  { label: "Man", value: "male" },
  { label: "Woman", value: "female" },
  { label: "Other", value: "diverse" },
])
GENDER_OPTIONS.set("de", [
  { label: "Mann", value: "male" },
  { label: "Frau", value: "female" },
  { label: "Divers", value: "diverse" },
])

export const JOB_POSITIONS = new Map<Language, Option[]>()

export enum JOB_POSITIONS_VALUES {
  Student = "Student",
  Resident = "Resident",
  Physician = "Physician",
  ChiefPhysician = "ChiefPhysician",
  SeniorPhysician = "SeniorPhysician",
  PrivatePhysician = "PrivatePhysician",
  Other = "Other",
}

JOB_POSITIONS.set("en", [
  { label: "Student", value: JOB_POSITIONS_VALUES.Student },
  { label: "Resident", value: JOB_POSITIONS_VALUES.Resident },
  { label: "Physician", value: JOB_POSITIONS_VALUES.Physician },
  { label: "Chief Physician", value: JOB_POSITIONS_VALUES.ChiefPhysician },
  { label: "Senior Physician", value: JOB_POSITIONS_VALUES.SeniorPhysician },
  { label: "Physician in Private Practice", value: JOB_POSITIONS_VALUES.PrivatePhysician },
  { label: "Other", value: JOB_POSITIONS_VALUES.Other },
])

JOB_POSITIONS.set("de", [
  { label: "Student:in", value: JOB_POSITIONS_VALUES.Student },
  { label: "Assistenzärzt:in", value: JOB_POSITIONS_VALUES.Resident },
  { label: "Fachärzt:in", value: JOB_POSITIONS_VALUES.Physician },
  { label: "Chefärzt:in", value: JOB_POSITIONS_VALUES.ChiefPhysician },
  { label: "Oberärzt:in", value: JOB_POSITIONS_VALUES.SeniorPhysician },
  { label: "Nidergelassene/r Ärzt:in", value: JOB_POSITIONS_VALUES.PrivatePhysician },
  { label: "Sonstiges", value: JOB_POSITIONS_VALUES.Other },
])

export const EFN_MUSK = "99 999 999 999999 9"
