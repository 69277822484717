export const getUserInitials = (userName: string) => {
  return (
    (userName?.split(" ")?.[0]?.charAt(0) || "").toUpperCase() +
    (userName?.split(" ")?.[1]?.charAt(0) || "").toUpperCase()
  )
}

export const getCollapsedString = (string: string, maxCollapsedStringLength: number): string => {
  const isNeedToCollapse = string.length > maxCollapsedStringLength

  return isNeedToCollapse ? `${string.slice(0, maxCollapsedStringLength)}...` : string
}

export const normalizeCoursePrice = (coursePrice?: number): string => {
  if (!coursePrice) return ""

  const cents = String(coursePrice).slice(-2)
  const euros = String(coursePrice).slice(0, -2) || 0
  return euros + "," + cents
}

// Function to validate EFN using the Luhn algorithm
export const validateEFN = (efnInput: string): boolean => {
  const efn = efnInput.replace(/\s+/g, "")

  // Check if the EFN is 15 digits long and consists only of numbers
  if (efn.length !== 15 || !/^\d+$/.test(efn)) {
    return false
  }

  // Implement the Luhn algorithm for the first 14 digits
  let total = 0
  const reverseDigits = efn.slice(0, -1).split("").reverse() // Exclude the check digit and reverse the order

  for (let i = 0; i < reverseDigits.length; i++) {
    let n = parseInt(reverseDigits[i], 10)

    // Double every second digit
    if (i % 2 === 0) {
      n *= 2
      if (n > 9) n -= 9 // Subtract 9 if the doubled value is greater than 9
    }

    total += n
  }

  // Calculate the check digit
  const checkDigit = (10 - (total % 10)) % 10

  // Validate the check digit against the last digit of EFN
  return checkDigit === parseInt(efn[efn.length - 1], 10)
}
