import { ReactNode, useState } from "react"
// context
import PlayerContext from "./context"

type VideoLanguageProviderProps = {
  children: ReactNode
}

const VideoLanguageProvider = ({ children }: VideoLanguageProviderProps) => {
  const [changedEntryPlayerId, setEntryPlayerId] = useState("")
  const [changedEpisodeId, setEpisodeId] = useState(0)

  return (
    <PlayerContext.Provider value={{ changedEntryPlayerId, setEntryPlayerId, changedEpisodeId, setEpisodeId }}>
      {children}
    </PlayerContext.Provider>
  )
}

export default VideoLanguageProvider
