import { useNavigate } from "react-router-dom"
import { Box } from "@mui/material"
import { useCallback } from "react"
// context
import { useModal } from "context/ModalContext/ModalContext"
// hooks
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
import { useWindowHeight } from "hooks/useWindowHeight"
import { useOnScreenKeyboardScrollFix } from "hooks/useOnScreenKeyboardScrollFix"
// components
import Icon from "shared/components/Icon"
// styles
import { SWrapper, SLeftSide, SRightSide, SLeftSideInner, SLogo, SImage, SVideoWrapper } from "./styles"
// types
import { Props } from "./types"

const OnboardingLayout = ({
  children,
  backPath,
  showBackBtn,
  logoColor,
  logoNameColor,
  rightBackgroundSrc,
  withoutFrame,
  WelcomeVideoComponent,
  commonRightSide,
  backBtnIconName = "close",
  focused,
  sx,
  sxLeftSide,
  sxRightSide,
  isModalView,
  backModal,
}: Props) => {
  const { isTablet } = useMUIBreakpoints()
  const navigate = useNavigate()
  const windowHeight = useWindowHeight()
  useOnScreenKeyboardScrollFix(focused as boolean)
  const { openModal, closeModal } = useModal()

  const handleGoBack = useCallback(() => {
    if (isModalView) {
      if (backBtnIconName === "close") {
        closeModal()
      } else if (backModal) {
        openModal(backModal)
      }
    } else {
      navigate((backPath || -1) as string, { replace: true })
    }
  }, [isModalView, backBtnIconName, closeModal, backModal, openModal, navigate, backPath])

  return (
    <SWrapper sx={[{ minHeight: isModalView ? "100%" : `${windowHeight}px` }, ...(Array.isArray(sx) ? sx : [sx])]}>
      {showBackBtn && (
        <Icon
          name={backBtnIconName}
          sx={[
            ({ breakpoints, spacing }) => ({
              zIndex: 10,
              position: "absolute",
              top: spacing(11),
              left: spacing(11),
              [breakpoints.down("lg")]: {
                top: spacing(8),
                left: spacing(8),
              },
              [breakpoints.down("sm")]: {
                top: spacing(4),
                left: spacing(4),
              },
            }),
          ]}
          withBackground
          backgroundSize={isTablet ? "small" : "large"}
          onClick={handleGoBack}
        />
      )}

      <SLogo>
        <Box mr={4}>
          <Icon
            sx={[
              (theme) => ({
                [theme.breakpoints.down("sm")]: {
                  svg: { width: "26px" },
                },
              }),
            ]}
            name="logo"
            fillColor={logoColor}
          />
        </Box>
        <Icon name="logoName" fillColor={logoNameColor} />
      </SLogo>
      <SLeftSide sx={sxLeftSide}>
        <SLeftSideInner>{children}</SLeftSideInner>
      </SLeftSide>
      <SRightSide sx={sxRightSide} commonRightSide={commonRightSide} withoutFrame={withoutFrame} focused={focused}>
        {WelcomeVideoComponent && <SVideoWrapper>{WelcomeVideoComponent}</SVideoWrapper>}
        <SImage
          commonRightSide={commonRightSide}
          src={rightBackgroundSrc}
          withoutFrame={withoutFrame}
          focused={focused}
        />
      </SRightSide>
    </SWrapper>
  )
}

export default OnboardingLayout
