import { useTranslation, TFunction } from "react-i18next"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
// hooks
import useExistedUser from "./hooks/useExistedUser"
import { useToggleVisibility } from "hooks/useToggleVisibility"
// components
import OnboardingLayout from "shared/components/OnboardingLayout"
import { TextField } from "shared/components/input"
import Button from "shared/components/Button"
// assets
import Onboarding from "assets/images/backgrounds/Onboarding1.svg"
// styles
import { SText, STitle } from "../styles"

interface FormData {
  email: string
}

const validator = (t: TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t("entryPoint:inputFields:email:errors:format"))
      .required(t("entryPoint:inputFields:email:errors:required")),
  })

interface EntryPointProps {
  isModalView?: boolean
}

const EntryPoint = ({ isModalView = false }: EntryPointProps) => {
  const { t } = useTranslation()
  const { isVisible: emailFocused, handleShow } = useToggleVisibility()

  const { isLoading, setEmail } = useExistedUser({ isModalView })

  const { handleSubmit, formState, control } = useForm<FormData>({
    resolver: yupResolver(validator(t)),
    defaultValues: {
      email: "",
    },
    reValidateMode: "onChange",
  })

  const handleFormSubmit = ({ email }: FormData) => {
    setEmail(email)
  }

  return (
    <OnboardingLayout
      focused={emailFocused}
      rightBackgroundSrc={Onboarding}
      isModalView={isModalView}
      showBackBtn={isModalView}
    >
      <STitle>{t("entryPoint:title")}</STitle>
      <SText variant="h5">{t("entryPoint:text")}</SText>
      <form
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Controller
          name="email"
          control={control}
          render={({
            field,
            formState: {
              errors: { email },
            },
          }) => (
            <TextField
              type="email"
              onFocus={handleShow}
              inputProps={field}
              error={!!email?.message}
              helperText={email?.message}
              sx={{ mb: 6 }}
              placeholder={t("entryPoint:inputFields:email:placeholder")}
            />
          )}
        />

        <Button
          sx={{ mt: "auto" }}
          type="submit"
          disabled={!!Object.keys(formState.errors).length || isLoading}
          color="secondary"
          fullWidth
          variant="contained"
        >
          {t("entryPoint:submitButtonText")}
        </Button>
      </form>
    </OnboardingLayout>
  )
}

export default EntryPoint
