import { Box, SxProps } from "@mui/material"
import { memo } from "react"
// services
import { KalturaPlayer } from "services/kaltura-player"
// hooks
import useKalutaPlayer from "./hooks/useKalutaPlayer"
import { useChangeVideoLanguage } from "pages/Course/hooks/useChangeVIdeoLanguage"
// types
import { EpisodeType, VLanguage } from "pages/Course/hooks/useCourse/types"
// styles
import { CustomTheme } from "styles/theme"
export interface PlayerContainerProps {
  languages: VLanguage[]
  currentEpisode: EpisodeType
  onEndPlaying?: () => void
  sx?: SxProps<CustomTheme>
}

const PlayerContainer = ({ languages, currentEpisode, onEndPlaying, sx }: PlayerContainerProps) => {
  const { currentLanguage, handleChangeVideoLanguage, changedEntryPlayerId, change } = useChangeVideoLanguage({
    languages,
    currentEpisode,
  })

  const { customizeConfig, handlePlayerLoaded, handleMediaLoaded } = useKalutaPlayer({
    onChangeVideoLanguage: handleChangeVideoLanguage,
    languages,
    currentLanguage,
  })

  const entryId = changedEntryPlayerId || currentEpisode.kalturaVideoId

  return (
    <Box
      sx={[
        ({ breakpoints, shape: { borderRadius } }) => ({
          paddingBottom: " 56.25%",
          position: "relative",
          marginBottom: "40px",
          [breakpoints.up("md")]: {
            borderRadius: borderRadius(8),
            overflow: "hidden",
          },
          [breakpoints.down("md")]: {
            marginLeft: "-40px",
            marginRight: "-40px",
            paddingBottom: " 62.55%",
          },
          [breakpoints.down("sm")]: {
            marginLeft: "-16px",
            marginRight: "-16px",
            paddingBottom: " 59.25%",
          },
          [breakpoints.down("xs")]: {
            marginLeft: "-16px",
            marginRight: "-16px",
            paddingBottom: " 60.25%",
          },
          [breakpoints.down("xxs")]: {
            marginLeft: "-16px",
            marginRight: "-16px",
            paddingBottom: " 62.25%",
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {!change && entryId && (
        <KalturaPlayer
          entryId={entryId}
          customizeConfig={customizeConfig}
          onPlayerLoaded={handlePlayerLoaded}
          onMediaLoaded={handleMediaLoaded}
          onEndPlaying={onEndPlaying}
          autoplay={false}
        />
      )}
    </Box>
  )
}

export default memo(PlayerContainer)
