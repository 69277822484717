export const MODALS = Object.freeze({
  AUTH: {
    ENTRY_POINT: "AUTH_ENTRY_POINT",
    LOGIN: "AUTH_LOGIN",
    REGISTRATION: "AUTH_REGISTRATION",
    FORGOT_PASSWORD: "AUTH_FORGOT_PASSWORD",
    RESET_PASSWORD: "AUTH_RESET_PASSWORD",
    EMAIL_CONFIRM: "AUTH_EMAIL_CONFIRM",
    CHECK_EMAIL_CONFIRM: "AUTH_CHECK_EMAIL_CONFIRM",
    WELCOME: "AUTH_WELCOME",
    ONBOARDING: {
      COUNTDOWN: "AUTH_ONBOARDING_COUNTDOWN",
      PERSONAL_DATA: "AUTH_ONBOARDING_PERSONAL_DATA",
      PROFESSIONAL_DATA: "AUTH_ONBOARDING_PROFESSIONAL_DATA",
    },
  },
})
