import { useEffect, useRef, useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
// services
import { resetPassword } from "api/auth"
import { removeIsModalAuth } from "services/auth"

// constants
import { LOGIN } from "constants/routes"
import { MODALS } from "constants/modals"
import { OPEN_MODAL } from "constants/searchParams"

interface IResetPassword {
  isModalView?: boolean
}

export const useResetPassword = ({ isModalView }: IResetPassword) => {
  const [error, setError] = useState()
  const [searchParams] = useSearchParams()
  const ref = useRef<ReturnType<typeof setTimeout>>()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    mutate: _resetPassword,
    isLoading,
    isError,
    isSuccess,
  } = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      ref.current = setTimeout(() => {
        if (isModalView) {
          removeIsModalAuth()
          const searchParams = new URLSearchParams(location.search)
          searchParams.set(OPEN_MODAL, MODALS.AUTH.LOGIN)
          navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true })
        } else {
          navigate(LOGIN)
        }
      }, 5000)
    },
    onError: (err: any) => {
      setError(err?.response?.data?.error?.message)
    },
  })

  useEffect(() => {
    return () => {
      if (ref.current) clearTimeout(ref.current)
    }
  }, [ref])

  return { resetPassword: _resetPassword, isLoading, isError, isSuccess, error, code: searchParams.get("code") }
}
