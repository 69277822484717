import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useCallback, useMemo } from "react"
// services & context
import { KalturaPlayerProvider } from "services/kaltura-player"
import VideoLanguageProvider from "context/VideoLanguage/VideoLanguageProvider"
import PlayerProvider from "context/Player/PlayerProvider"
// hooks
import useWelcomePage from "./hooks/useWelcomePage"
// components
import OnboardingLayout from "shared/components/OnboardingLayout"
import Button from "shared/components/Button"
import PlayerContainer from "pages/Course/components/KalturePlayer"
// constants
import { LOGIN } from "constants/routes"
// assets
import WelcomeBackground from "assets/images/backgrounds/WelcomeBackground.svg"
// constants
import { REACT_APP_KALTURA_PARTNER_ID, REACT_APP_KALTURA_UI_CONFIG_ID } from "constants/user"
// styles
import { SText, STitle } from "../styles"
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
import { useModal } from "context/ModalContext/ModalContext"

interface WelcomeProps {
  isModalView?: boolean
}

const Welcome = ({ isModalView }: WelcomeProps) => {
  const { t } = useTranslation()
  const { currentVideo, videoLanguages } = useWelcomePage()
  const { isMobile } = useMUIBreakpoints()
  const { closeModal } = useModal()
  const navigate = useNavigate()

  const logoColor = useMemo(() => (isMobile ? "black" : ""), [isMobile])

  const handleSubmitWelcome = useCallback(() => {
    if (isModalView) {
      closeModal()
    } else {
      navigate(LOGIN)
    }
  }, [isModalView])

  const WelcomeVideo = useMemo(
    () => (
      <PlayerProvider>
        <KalturaPlayerProvider
          playerBundleConfig={{
            bundlerUrl: "https://cdnapisec.kaltura.com",
            partnerId: REACT_APP_KALTURA_PARTNER_ID,
            ks: "",
            uiConfId: REACT_APP_KALTURA_UI_CONFIG_ID || "51316052",
          }}
        >
          <VideoLanguageProvider>
            <PlayerContainer
              currentEpisode={currentVideo as any}
              languages={videoLanguages}
              sx={({ breakpoints }) => ({
                paddingBottom: " 56.25%",
                marginBottom: "0",
                [breakpoints.up("md")]: {
                  borderRadius: "24px",
                },
                [breakpoints.down("md")]: {
                  borderRadius: "24px",
                  overflow: "hidden",
                  isolation: "isolate",
                  marginLeft: "0",
                  marginRight: "0",
                  paddingBottom: " 56.25%",
                },
                [breakpoints.down("sm")]: {
                  marginLeft: "0",
                  marginRight: "0",
                  paddingBottom: " 56.25%",
                },
                [breakpoints.down("xs")]: {
                  marginLeft: "0",
                  marginRight: "0",
                  paddingBottom: " 56.25%",
                },
                [breakpoints.down("xxs")]: {
                  marginLeft: "0",
                  marginRight: "0",
                  paddingBottom: " 56.25%",
                },
              })}
            />
          </VideoLanguageProvider>
        </KalturaPlayerProvider>
      </PlayerProvider>
    ),
    [currentVideo]
  )

  return (
    <OnboardingLayout
      showBackBtn
      withoutFrame
      rightBackgroundSrc={WelcomeBackground}
      backPath={LOGIN}
      WelcomeVideoComponent={WelcomeVideo}
      commonRightSide
      logoColor={logoColor}
      logoNameColor={logoColor}
      sx={{ background: "transparent" }}
      sxLeftSide={{ background: "transparent" }}
      sxRightSide={[
        ({ breakpoints }) => ({
          [breakpoints.down("sm")]: {
            maxHeight: "none",
            height: "auto",
            pt: 12,
            mt: 4,
          },

          [breakpoints.down("xs")]: {},

          [breakpoints.down("xxs")]: {},
        }),
      ]}
      isModalView={isModalView}
    >
      <>
        <STitle
          sx={[
            ({ breakpoints }) => ({
              [breakpoints.down("sm")]: { mb: 12 },
            }),
          ]}
        >
          {t("welcome:title")}
        </STitle>
        <SText>{t("welcome:text")}</SText>
        {/* <Link style={{ textDecoration: "none", marginTop: "auto" }} to={LOGIN}> */}
        <Button color="secondary" fullWidth variant="contained" onClick={handleSubmitWelcome}>
          {t("welcome:submitButtonText")}
        </Button>
        {/* </Link> */}
      </>
    </OnboardingLayout>
  )
}

export default Welcome
