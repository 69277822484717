import language from "assets/icons/language.svg"
// types
import { VLanguage } from "pages/Course/hooks/useCourse/types"

export type UseLanguagesButton = {
  onChangeVideoLanguage: (lang: VLanguage) => void
  languages: VLanguage[]
  currentLanguage: VLanguage
}

const useLanguagesButton = ({
  onChangeVideoLanguage,
  languages,
  currentLanguage,
}: UseLanguagesButton): {
  label: string
  presets: string[]
  container: string
  beforeComponent: string
  get: () => void
} => {
  // @ts-ignore
  const Component = window.KalturaPlayer.ui.preact.Component
  // @ts-ignore
  const h = window.KalturaPlayer.ui.h

  class LanguagesDropdown extends Component {
    state: { activeLang: VLanguage; isOpen: boolean } = {
      activeLang: currentLanguage,
      isOpen: false,
    }

    handleChangeActiveLang(lang: VLanguage, e: any) {
      e.stopPropagation()
      // @ts-ignore
      this.setState((prev) => ({ ...prev, activeLang: lang }))
      // @ts-ignore
      this.props.onClick(lang)
    }

    toggleOpen() {
      // @ts-ignore
      this.setState((prev) => ({ ...prev, isOpen: !this.state.isOpen }))
    }

    render(props: any) {
      const {
        state: { isOpen, activeLang },
        // @ts-ignore
        props: { languages },
      } = this

      return h(
        "div",
        {
          onmouseenter: () => this.toggleOpen(),
          onmouseleave: () => this.toggleOpen(),
          className: "playkit-control-button-container player-languages-switcher",
          style: {
            position: "relative",
          },
        },
        props.children,
        h(
          "div",
          {
            style: {
              position: "absolute",
              bottom: "15px",
              right: 0,
              background: "#222222",
              borderRadius: "4px",
              visibility: "hidden",
              opacity: 0,
              zIndex: 2,
              transition: `opacity .2s cubic-bezier(.42, 0, .58, 1)`,
              ...(isOpen
                ? {
                    visibility: "visible",
                    opacity: 1,
                  }
                : {}),
            },
          },

          languages.map((lang: VLanguage) => {
            return h(
              "div",
              {
                onClick: (e: any) => this.handleChangeActiveLang(lang, e),
                style: {
                  color:
                    // TODO: temporary decision, remove when we will have courses in 2 languages
                    languages.length > 1
                      ? activeLang.localizationKey === lang.localizationKey
                        ? "#16F3A2"
                        : "#fff"
                      : "#16F3A2",
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 500,
                  padding: "8px 12px",
                },
              },
              lang.videoLanguage
            )
          })
        )
      )
    }
  }

  const ChangeLanguages = h(
    LanguagesDropdown,
    {
      onClick: onChangeVideoLanguage,
      languages,
    },
    h(
      "div",
      {
        style: {
          cursor: "pointer",
          opacity: 0.85,
        },
      },
      h("img", {
        onmouseenter: (e: any) => (e.target.style.opacity = 1),
        onmouseleave: (e: any) => (e.target.style.opacity = 0.85),
        src: language,
        style: { width: "15px", height: "15px" },
      })
    )
  )

  return {
    label: "langComponent",
    presets: ["Playback"],
    container: "BottomBarRightControls",
    beforeComponent: "Settings",
    get: () => ChangeLanguages,
  }
}

export default useLanguagesButton
