import { useContext } from "react"
import PlayerContext from "./context"

export const usePlayerContext = () => {
  const context = useContext(PlayerContext)

  if (!context) {
    throw new Error("`usePlayerContext` should be used within a `PlayerProvider`")
  }

  return context
}

export default usePlayerContext
