// styles
import { customStyled } from "styles/theme"
import { Typography } from "@mui/material"

export const STitle = customStyled(Typography)(
  ({
    theme: {
      breakpoints,
      typography: { mobileH1 },
      spacing,
    },
  }) => ({
    fontSize: "32px",
    marginBottom: spacing(4),
    [breakpoints.down("sm")]: {
      ...mobileH1,
      marginBottom: spacing(4),
    },
  })
)

export const SText = customStyled(Typography)(
  ({
    theme: {
      palette,
      breakpoints,
      typography: { mobileH3 },
      spacing,
    },
  }) => ({
    color: palette.grey["600"],
    marginBottom: spacing(7),
    lineHeight: 1.2,
    [breakpoints.down("sm")]: {
      ...mobileH3,
    },

    "& span": {
      color: palette.secondary.main,
    },
  })
)
