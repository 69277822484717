import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
// api
import { getIsExistStrapiUserByEmail, GetIsExistStrapiUserByEmailResponse, USER_KEYS } from "api"
// context
import { useModal } from "context/ModalContext/ModalContext"
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// constants
import { LOGIN, REGISTRATION } from "constants/routes"
import { MODALS } from "constants/modals"

interface IUseExistedUser {
  isModalView?: boolean
}

const useExistedUser = ({ isModalView }: IUseExistedUser) => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const { setUser } = useAuthenticationContext()
  const { openModal } = useModal()

  const { isFetching, isError, error } = useQuery<GetIsExistStrapiUserByEmailResponse>({
    queryKey: [USER_KEYS.STRAPI_USER, email],
    queryFn: () => getIsExistStrapiUserByEmail(email),
    enabled: !!email,
    onSuccess: (isUserExist) => {
      handleUserExistence(isUserExist)
    },
  })

  const handleUserExistence = (isUserExist: boolean) => {
    setUser({
      email,
      userName: email,
    })

    if (isUserExist) {
      if (isModalView) {
        openModal(MODALS.AUTH.LOGIN)
      } else {
        navigate(LOGIN, { replace: true })
      }
    } else {
      if (isModalView) {
        openModal(MODALS.AUTH.REGISTRATION)
      } else {
        navigate(REGISTRATION, { replace: true })
      }
    }
  }

  return { isLoading: isFetching, setEmail, isError, error }
}

export default useExistedUser
