import axios from "axios"
import qs from "qs"
// types
import { FetchedUser } from "types/user"

type LoginRequest = {
  email: string
  password: string
}

export type LoginResponse = {
  jwt: string
  user: FetchedUser
}

export const LOGIN = {
  STRAPI_USER: "strapiUser",
}

export const login = async ({ email, password }: LoginRequest) => {
  const params = qs.stringify(
    {
      fields: ["id"],
      populate: "*",
    },
    { encodeValuesOnly: true }
  )

  const url = `/auth/local?${params}`

  const response = await axios.post<LoginResponse>(url, {
    identifier: email,
    password,
  })

  return response.data
}

type RegistrationRequest = {
  email: string
  password: string
  acceptedTermsAndConditions: boolean
  acceptedEmailNewsletter: boolean
  acceptedTrackingBehavior: boolean
}

export type RegistrationResponse = {
  user: FetchedUser
}

export const registration = async ({
  email,
  password,
  acceptedTermsAndConditions,
  acceptedEmailNewsletter,
  acceptedTrackingBehavior,
}: RegistrationRequest) => {
  const params = qs.stringify(
    {
      populate: "*",
    },
    { encodeValuesOnly: true }
  )
  const url = `/auth/local/register?${params}`

  const response = await axios.post<RegistrationResponse>(url, {
    email,
    password,
    username: email,
    acceptedTermsAndConditions,
    acceptedEmailNewsletter,
    acceptedTrackingBehavior,
  })

  return response.data
}

type VerifyEmailRequest = {
  email: string
}

export const verifyEmail = async ({ email }: VerifyEmailRequest) => {
  const params = qs.stringify(
    {
      populate: "*",
    },
    { encodeValuesOnly: true }
  )

  const url = `/auth/send-email-confirmation?${params}`

  const response = await axios.post<unknown>(url, {
    email,
  })

  return response.data
}

type ForgotPasswordRequest = {
  email: string
}

export const forgotPassword = async ({ email }: ForgotPasswordRequest) => {
  const params = qs.stringify(
    {
      populate: "*",
    },
    { encodeValuesOnly: true }
  )

  const url = `/auth/forgot-password?${params}`

  const response = await axios.post<unknown>(url, {
    email,
  })

  return response.data
}

type ResetPasswordRequest = {
  password: string
  passwordConfirmation: string
  code: string
}

export const resetPassword = async ({ password, passwordConfirmation, code }: ResetPasswordRequest) => {
  const params = qs.stringify(
    {
      populate: "*",
    },
    { encodeValuesOnly: true }
  )

  const url = `/auth/reset-password?${params}`

  const response = await axios.post<unknown>(url, { password, passwordConfirmation, code })

  return response.data
}
