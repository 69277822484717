import { useState, useEffect, useCallback } from "react"
import { flushSync } from "react-dom"
import { usePlayerUpdates, PlayerEventsTypes, usePlayer } from "services/kaltura-player"
// context
import usePlayerContext from "context/Player/usePlayerContext"
// hooks
import useLanguagesButton from "./useLanguagesButton"
// types
import { VLanguage } from "pages/Course/hooks/useCourse/types"

export type UseKalutaPlayer = {
  onChangeVideoLanguage: (lang: VLanguage) => void
  languages: VLanguage[]
  currentLanguage: VLanguage
}

const useKalutaPlayer = ({
  onChangeVideoLanguage,
  languages,
  currentLanguage,
}: UseKalutaPlayer): {
  handleMediaLoaded: (playerId: string) => void
  handlePlayerLoaded: ({ playerId }: { playerId: string }) => void
  customizeConfig: (config: any) => any
} => {
  const [playerId, setPlayerId] = useState("")
  const [playerMediaId, setPlayerMediaId] = useState("")
  const [, setPlayerEvent] = useState<PlayerEventsTypes | null>(null)
  const { playerEvents$ } = usePlayerUpdates(playerId)
  const { playerSeek } = usePlayer(playerId)

  const { isMediaLoaded, setIsMediaLoaded, setPlayerId: setId, seekTo, setSeekTo } = usePlayerContext()

  useEffect(() => {
    if (playerMediaId && isMediaLoaded && seekTo) {
      setSeekTo(0)
    }
  }, [playerMediaId, isMediaLoaded])

  useEffect(() => {
    if (isMediaLoaded && seekTo !== 0) {
      playerSeek({ seekTo, pause: false })
    }
  }, [isMediaLoaded, seekTo])

  useEffect(() => {
    if (!playerId) {
      return
    }

    const eventsSubscription = playerEvents$.subscribe((result) => setPlayerEvent(result.type))

    return () => {
      eventsSubscription.unsubscribe()
    }
  }, [playerId, playerEvents$])

  const handleMediaLoaded = (playerId: string) => {
    if (!playerId) {
      setIsMediaLoaded(false)
      setPlayerMediaId("")

      return
    }

    if (playerId !== playerMediaId) {
      flushSync(() => setIsMediaLoaded(false))
    }

    setPlayerMediaId(playerId)
    flushSync(() => setIsMediaLoaded(true))
  }

  const handlePlayerLoaded = (data: { playerId: string }) => {
    const { playerId } = data

    if (!playerId) {
      return
    }

    setPlayerId(playerId)
    setId(playerId)
  }

  const customizeConfig = useCallback(
    (config: any) => {
      const languagesButtonConfig = useLanguagesButton({ onChangeVideoLanguage, languages, currentLanguage })
      const newConfig = {
        ...config,
        ui: {
          ...(config.ui || {}),
          uiComponents: [...((config.ui || {}).uiComponents || []), languagesButtonConfig],
        },
      }

      return newConfig
    },
    [onChangeVideoLanguage, languages, currentLanguage]
  )

  return { handleMediaLoaded, handlePlayerLoaded, customizeConfig }
}

export default useKalutaPlayer
