import { createContext } from "react"

type VideoLanguageContext = {
  changedEntryPlayerId: string
  setEntryPlayerId: (id: string) => void
  changedEpisodeId: number
  setEpisodeId: (episodeId: number) => void
}

const VideoLanguageContext = createContext<VideoLanguageContext>({} as VideoLanguageContext)

export default VideoLanguageContext
