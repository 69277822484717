import { useSearchParams } from "react-router-dom"
// hooks
import useCheckEmailConfirmation from "./hooks/useCheckEmailConfirmation"
// components
import GlobalLoading from "shared/components/GlobalLoading"

const CheckEmailConfirmation = () => {
  const [searchParams] = useSearchParams()
  const confirmationToken = searchParams.get("confirmation")
  const email = searchParams.get("email")

  if (!confirmationToken || !email)
    throw new Error("When confirming email, email and verificationToken must be provided in url.")

  useCheckEmailConfirmation({
    confirmationToken: confirmationToken || "",
    email: email || "",
  })

  return <GlobalLoading />
}

export default CheckEmailConfirmation
