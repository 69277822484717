import { useQuery } from "@tanstack/react-query"
// api
import { USER_NOTIFICATIONS, getUserUnreadNotification, GetUserUnreadNotificationResponse } from "api"

interface IUseUnreadNotification {
  disabled?: boolean
}

export const useUnreadNotification = ({ disabled = false }: IUseUnreadNotification) => {
  const { data: fetchedUserUnreadNotifications } = useQuery<GetUserUnreadNotificationResponse>({
    enabled: !disabled,
    queryKey: [USER_NOTIFICATIONS.GET_USER_UNREAD_NOTIFICATION],
    queryFn: () => getUserUnreadNotification(),
  })

  return {
    haveUnreadNotification: fetchedUserUnreadNotifications?.data[0]?.attributes?.isRead === false,
  }
}
