import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
// services
import { login } from "api/auth"
import { getBeforeLoginUrl, removeBeforeLoginUrl } from "services/auth"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// constants
import { HOME } from "constants/routes"

export const useLogin = () => {
  const navigate = useNavigate()
  const [error, setError] = useState()
  const { setAuthToken } = useAuthenticationContext()

  const {
    mutate: logIn,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: login,
    onSuccess: ({ jwt }) => {
      setAuthToken(jwt)
      const beforeLoginUrl = getBeforeLoginUrl()
      removeBeforeLoginUrl()

      navigate(beforeLoginUrl || HOME, { replace: true })
    },
    onError: (err: any) => {
      setError(err?.response?.data?.error?.message)
    },
  })

  return { logIn, isLoading, isError, error }
}
