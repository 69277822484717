import React, { createContext, useContext, useState } from "react"
import GlobalModalHandler from "shared/components/modals/GlobalModalHandler"

interface ModalContextProps {
  currentModal: string | null
  modalData?: any
  openModal: (modalName: string, data?: any) => void
  closeModal: () => void
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined)

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [modalData, setModalData] = useState<any>(null)

  const openModal = (modalName: string, data: any = null) => {
    setCurrentModal(modalName)
    setModalData(data)
  }

  const closeModal = () => {
    setCurrentModal(null)
    setModalData(null)
  }

  return (
    <ModalContext.Provider value={{ currentModal, modalData, openModal, closeModal }}>
      {children}
      <GlobalModalHandler />
    </ModalContext.Provider>
  )
}

export const useModal = (): ModalContextProps => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider")
  }
  return context
}
