import { useContext } from "react"
import VideoLanguageContext from "./context"

export const useVideoLanguageContext = () => {
  const context = useContext(VideoLanguageContext)

  if (!context) {
    throw new Error("`useVideoLanguageContext` should be used within a `VideoLanguageProvider`")
  }

  return context
}

export default useVideoLanguageContext
