import { useEffect, useRef } from "react"

export const useOnScreenKeyboardScrollFix = (trigger: boolean) => {
  const ref = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    if (trigger) {
      ref.current = setTimeout(() => {
        window.scrollTo(0, 0)
      }, 200)
    }

    return () => {
      if (ref.current) {
        clearTimeout(ref.current)
      }
    }
  }, [trigger])
}
