import { Avatar, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
// hooks
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// utils
import { getUserInitials } from "utils/string"
// constants
import { SETTINGS } from "constants/routes"
import { REACT_APP_STRAPI_URL } from "constants/endpoints"
// styles
import { CardWrapper } from "./styles"

const UserCard = () => {
  const { t } = useTranslation()

  const {
    user: { firstName, lastName, cmePoints: userCmePoints, pictureUrl, specialty },
  } = useAuthenticationContext()

  const userName = `${firstName} ${lastName}`

  const { isTablet } = useMUIBreakpoints()

  return (
    <CardWrapper to={SETTINGS}>
      <Avatar
        sx={[
          ({ spacing, breakpoints }) => ({
            mb: spacing(4),
            [breakpoints.down("sm")]: {
              width: "48px",
              height: "48px",
              mb: 0,
            },
          }),
        ]}
        src={`${REACT_APP_STRAPI_URL}${pictureUrl}`}
      >
        {firstName && getUserInitials(userName)}
      </Avatar>
      {!isTablet && (
        <>
          <Typography mb={2} variant="description" color="white.main" component="p">
            {firstName ? userName : t("mainLayout:userNameMock")}
          </Typography>
          <Typography mb={9} variant="paragraph" color="black.lacquer.300" component="p">
            {specialty || t("mainLayout:userSpecialtyMock")}
          </Typography>
          <Typography variant="h1" color="green.hyperpop.500" component="h2">
            {userCmePoints || 0}
          </Typography>
          <Typography variant="description" color="black.lacquer.300" component="p">
            {t("mainLayout:cmePoints")}
          </Typography>
        </>
      )}
    </CardWrapper>
  )
}

export default UserCard
