import { useTranslation } from "react-i18next"
import { useCallback } from "react"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// components
import Button from "shared/components/Button"
// styles
import { SText, STitle } from "../../styles"
// types
import { useModal } from "context/ModalContext/ModalContext"
import { MODALS } from "constants/modals"
// utils
import getHoursLeftFromCreation from "utils/getHoursLeftFromCreation"

const Countdown = () => {
  const { t } = useTranslation()
  const { openModal } = useModal()
  const {
    user: { createdAt },
  } = useAuthenticationContext()

  const handleButtonClick = useCallback(() => {
    openModal(MODALS.AUTH.ONBOARDING.PERSONAL_DATA)
  }, [])

  const hoursLeftFromUserCreation = getHoursLeftFromCreation(createdAt)

  return (
    <div>
      <STitle>{t("onboarding:countdown:title")}</STitle>
      {hoursLeftFromUserCreation <= 0 ? (
        <SText variant="h5">{t("onboarding:countdown:textRestricted")}</SText>
      ) : (
        <SText variant="h5">
          {t("onboarding:countdown:textFirst")}
          <span>{t("onboarding:countdown:textSecond", { hoursLeft: hoursLeftFromUserCreation })}</span>
        </SText>
      )}
      <Button type="button" color="secondary" fullWidth variant="contained" onClick={handleButtonClick}>
        {t("onboarding:countdown:buttonText")}
      </Button>
    </div>
  )
}

export default Countdown
