import axios from "axios"
import qs from "qs"
// constants
import { REACT_APP_STRAPI_API_URL } from "constants/endpoints"
import { languagesTransformer } from "i18n"
// types
import { Pagination } from "types/api"
import { Language } from "types/i18n"
import { Specialty } from "types/specialties"

type GetSpecialtiesRequest = { locale?: Language; filterValue?: string; withCoursesOnly?: boolean }

export type GetSpecialtiesResponse = {
  data: Specialty[]
  meta: {
    pagination: Pagination
  }
}

export const COURSE_KEYS = Object.freeze({
  ALL_COURSE: "allCourse",
  SPECIALTIES: "specialties",
  FILTERED_SPECIALTIES: "filteredSpecialties",
  COURSES_BY_CATEGORY: "coursesByCategory",
  COMMON_COURSES: "commonCourses",
  SINGLE_COURSES: "singleCourses",
  EPISODES: "episodes",
  INTROS: "intros",
  COURSE: "course",
  COURSE_PUBLIC: "coursePublic",
  COURSE_PRIVATE: "coursePrivate",
  COURSE_BY_SUPER_COURSE_TITLE: "courseBySuperCourseTitle",
  EPISODE_BY_SUPER_EPISODE_TITLE: "episodeBySuperEpisodeTitle",
})

export const getSpecialties = async ({ locale = "en", filterValue, withCoursesOnly }: GetSpecialtiesRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      pagination: {
        page: 1,
        pageSize: 99,
      },
      filters: {
        ...(withCoursesOnly && {
          courses: {
            id: {
              $notNull: true,
            },
          },
        }),
        ...(filterValue
          ? {
              name: {
                $containsi: filterValue,
              },
            }
          : {}),
      },
      locale: languagesTransformer[_locale],
      sort: { name: "ASC" },
      populate: {
        specialtyImage: {
          fields: ["url"],
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `${REACT_APP_STRAPI_API_URL}specialties?${params}`

  const response = await axios.get<GetSpecialtiesResponse>(url)

  return response.data
}
