import { Alert, Snackbar, Typography } from "@mui/material"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
// hooks
import { useResetPassword } from "./hooks/useResetPassword"
import { useToggleVisibility } from "hooks/useToggleVisibility"
import useToggleSnackbar from "hooks/useToggleSnackbar"
// components
import OnboardingLayout from "shared/components/OnboardingLayout"
import Button from "shared/components/Button"
import { TextField } from "shared/components/input"
// constants
import { LOGIN } from "constants/routes"
// assets
import Onboarding2 from "assets/images/backgrounds/Onboarding2.svg"
// styles
import { STitle } from "../styles"
import { MODALS } from "constants/modals"

interface FormData {
  password: string
  passwordConfirmation: string
}

interface ForgotPasswordProps {
  isModalView?: boolean
}

const validator = (t: TFunction) =>
  yup.object().shape({
    password: yup
      .string()
      .required(t("resetPassword:inputFields:password:errors:required"))
      .min(8, t("resetPassword:inputFields:password:errors:min")),

    passwordConfirmation: yup
      .string()
      .required(t("resetPassword:inputFields:password:errors:required"))
      .min(8, t("resetPassword:inputFields:password:errors:min"))
      .oneOf([yup.ref("password"), null], t("resetPassword:inputFields:passwordConfirmation:errors:match")),
  })

const ForgotPassword = ({ isModalView }: ForgotPasswordProps) => {
  const { t } = useTranslation()
  const { resetPassword, isLoading, isSuccess, isError, error, code } = useResetPassword({ isModalView })
  const { open, handleClose } = useToggleSnackbar(isError)
  const { isVisible: fieldFocused, handleShow } = useToggleVisibility()

  const { handleSubmit, formState, control } = useForm<FormData>({
    resolver: yupResolver(validator(t)),
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
    reValidateMode: "onChange",
  })

  const handleFormSubmit = ({ password, passwordConfirmation }: FormData) => {
    resetPassword({ password, passwordConfirmation, code: code! })
  }

  return (
    <OnboardingLayout
      focused={fieldFocused}
      showBackBtn
      withoutFrame
      rightBackgroundSrc={Onboarding2}
      backPath={LOGIN}
      isModalView={isModalView}
      backModal={MODALS.AUTH.LOGIN}
    >
      <STitle>{t("resetPassword:title")}</STitle>
      <form
        style={{ height: "100%", flexGrow: 1, display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Controller
          name="password"
          control={control}
          render={({
            field,
            formState: {
              errors: { password },
            },
          }) => (
            <TextField
              onFocus={handleShow}
              onCopy={(e) => e.preventDefault()}
              type="password"
              inputProps={field}
              error={!!password?.message}
              helperText={password?.message}
              sx={{ mb: 4 }}
              placeholder={t("resetPassword:inputFields:password:placeholder")}
              infoText={t("resetPassword:inputFields:password:infoText")}
            />
          )}
        />
        <Controller
          name="passwordConfirmation"
          control={control}
          render={({
            field,
            formState: {
              errors: { passwordConfirmation },
            },
          }) => (
            <TextField
              onFocus={handleShow}
              onPaste={(e) => e.preventDefault()}
              type="password"
              inputProps={field}
              error={!!passwordConfirmation?.message}
              helperText={passwordConfirmation?.message}
              sx={{ mb: 4 }}
              placeholder={t("resetPassword:inputFields:passwordConfirmation:placeholder")}
              infoText={t("resetPassword:inputFields:passwordConfirmation:infoText")}
            />
          )}
        />
        <Button
          type="submit"
          disabled={!!Object.keys(formState.errors).length || isLoading}
          color="secondary"
          fullWidth
          variant="contained"
        >
          {t("resetPassword:submitButtonText")}
        </Button>
      </form>
      {isSuccess && (
        <Typography sx={{ maxWidth: 438 }} mt={3} variant="h5" color="secondary.main">
          {t("resetPassword:text")}
        </Typography>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={error}
        onClose={handleClose}
      >
        <Alert severity="error" sx={{ width: "100%" }} onClose={handleClose} closeText="Close">
          {error}
        </Alert>
      </Snackbar>
    </OnboardingLayout>
  )
}

export default ForgotPassword
