import { useTranslation } from "react-i18next"
import { Typography, Alert, Snackbar } from "@mui/material"
// hooks
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
import useToggleSnackbar from "hooks/useToggleSnackbar"
import useVerifyEmail from "./hooks/useVerifyEmail"
// components
import OnboardingLayout from "shared/components/OnboardingLayout"
import Button from "shared/components/Button"
// constants
import { ENTRY_POINT } from "constants/routes"
// assets
import Onboarding from "assets/images/backgrounds/Onboarding1.svg"
// styles
import { SText, STitle } from "../styles"
import { MODALS } from "constants/modals"

interface EmailConfirmationProps {
  isModalView?: boolean
}

const Requested = ({ isModalView }: EmailConfirmationProps) => {
  const { t } = useTranslation()
  const { isLoading, isError, error, expiredTime, expiredTimeSeconds, handleResendVerifyEmail } = useVerifyEmail()
  const { open, handleClose } = useToggleSnackbar(isError)
  const { user } = useAuthenticationContext()

  return (
    <OnboardingLayout
      showBackBtn
      rightBackgroundSrc={Onboarding}
      backPath={ENTRY_POINT}
      backModal={MODALS.AUTH.ENTRY_POINT}
      isModalView={isModalView}
    >
      <STitle>{t("emailConfirm:title", { username: user.userName })}</STitle>
      <SText variant="h5">{t("emailConfirm:text", { email: user.email })}</SText>
      <Typography mb={9} display="block" variant="mobileH4">
        {t("emailConfirm:willResend", { time: expiredTime })}
      </Typography>
      <Button
        href="mailto:"
        onClick={(e) => expiredTimeSeconds <= 0 && handleResendVerifyEmail(e)}
        color="secondary"
        fullWidth
        variant="contained"
        disabled={isLoading}
        sx={{ cursor: "pointer", mt: "auto" }}
      >
        {expiredTimeSeconds <= 0 ? t("emailConfirm:resend") : t("emailConfirm:buttonText")}
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={error}
        onClose={handleClose}
      >
        <Alert severity="error" sx={{ width: "100%" }} onClose={handleClose} closeText="Close">
          {error}
        </Alert>
      </Snackbar>
    </OnboardingLayout>
  )
}

export default Requested
