const ALLOWED_HOURS = 48

/**
 * Calculates the number of hours left from the creation time until the allowed hours limit is reached.
 *
 * @param createdAt - The creation time as a string.
 * @param allowedHours - The number of allowed hours from the creation time. Defaults to ALLOWED_HOURS.
 * @returns The number of hours left from the creation time.
 */

const getHoursLeftFromCreation = (createdAt: string, allowedHours: number = ALLOWED_HOURS): number => {
  const millisecondsPassedFromCreation = Date.now() - new Date(createdAt).getTime()
  const hoursPassedFromCreation = Math.floor(millisecondsPassedFromCreation / (1000 * 60 * 60))
  return allowedHours - hoursPassedFromCreation
}

export default getHoursLeftFromCreation
