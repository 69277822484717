import { createContext } from "react"

type PlayerContext = {
  playerId: string
  setPlayerId: (id: string) => void
  isMediaLoaded: boolean
  setIsMediaLoaded: (value: boolean) => void
  seekTo: number
  setSeekTo: (seek: number) => void
}

const PlayerContext = createContext<PlayerContext>({} as PlayerContext)

export default PlayerContext
