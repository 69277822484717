import { Alert, Box, Snackbar } from "@mui/material"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useCallback, useMemo } from "react"
import { languagesMap } from "i18n"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// hooks
import { useOnboarding } from "hooks/useOnboarding"
import useToggleSnackbar from "hooks/useToggleSnackbar"
// components
import Button from "shared/components/Button"
import { Autocomplete, DataPicker, TextField } from "shared/components/input"
import Tag from "shared/components/Tag"
// constants
import { PROFESSIONAL_DATA } from "constants/routes"
import { GENDER_OPTIONS } from "constants/auth"
// styles
import { SText, STitle } from "../../styles"
// types
import { Option } from "shared/components/input/Autocomplete/types"
import { Language } from "types/i18n"
import { useNavigate } from "react-router-dom"
import { useModal } from "context/ModalContext/ModalContext"
import { MODALS } from "constants/modals"

interface FormData {
  title: string
  userFirstName: string
  userLastName: string
  userGender: Option
  userLocale: Option
  userDateOfBirth: string
}

interface PersonalDataProps {
  isModalView?: boolean
}

const validator = (t: TFunction) =>
  yup.object().shape({
    userFirstName: yup.string().required(t("onboarding:personalData:inputFields:userFirstName:errors:required")),
    userLastName: yup.string().required(t("onboarding:personalData:inputFields:userLastName:errors:required")),
    userGender: yup.object().required(t("onboarding:personalData:inputFields:userGender:errors:required")).nullable(),
    userLocale: yup.object().required(t("onboarding:personalData:inputFields:userLocale:errors:required")).nullable(),
    userDateOfBirth: yup
      .string()
      .required(t("onboarding:personalData:inputFields:userDateOfBirth:errors:required"))
      .test({
        name: "isValidFormat",
        test(value: string | undefined, ctx) {
          if (value === "Invalid Date") {
            return ctx.createError({ message: t("onboarding:personalData:inputFields:userDateOfBirth:errors:format") })
          }

          return true
        },
      })
      .nullable(),
  })

const PersonalData = ({ isModalView }: PersonalDataProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { openModal } = useModal()

  const onSuccessfulSubmit = useCallback(() => {
    if (isModalView) {
      openModal(MODALS.AUTH.ONBOARDING.PROFESSIONAL_DATA)
    } else {
      navigate(PROFESSIONAL_DATA)
    }
  }, [isModalView])

  const { updateUserData, isLoading, isError, error } = useOnboarding(onSuccessfulSubmit)
  const { open, handleClose } = useToggleSnackbar(isError)
  const {
    user: { userProfileId, firstName, lastName, gender, locale, title, userDateOfBirth },
  } = useAuthenticationContext()

  const languagesArray = useMemo(() => {
    const languagesArray = []

    for (const languageKey in languagesMap) {
      languagesArray.push({ label: languagesMap[languageKey], value: languageKey })
    }

    return languagesArray
  }, [languagesMap])

  const genderOptions = useMemo(
    () => GENDER_OPTIONS.get(i18n.language.slice(0, 2) as Language)!,
    [GENDER_OPTIONS, i18n.language]
  )

  const { handleSubmit, formState, control } = useForm<FormData>({
    resolver: yupResolver(validator(t)),
    defaultValues: {
      title: title || "",
      userFirstName: firstName || "",
      userLastName: lastName || "",
      userGender: genderOptions.find((g) => g.value === gender) || genderOptions[0],
      userLocale: languagesArray.find((l) => l.value === locale) || languagesArray[0],
      userDateOfBirth: new Date(userDateOfBirth).toISOString() || "",
    },
    reValidateMode: "onChange",
  })

  const handleFormSubmit = ({
    title,
    userFirstName,
    userLastName,
    userGender,
    userLocale,
    userDateOfBirth,
  }: FormData) => {
    updateUserData({
      userProfileId,
      userFirstName,
      userLastName,
      title,
      userGender: userGender.value,
      userLocale: userLocale.value,
      userDateOfBirth,
    })
  }

  return (
    <div>
      <Tag sx={{ mb: 4 }} variant="lightgreen">
        {t("onboarding:personalData:step", { current: 1, from: isModalView ? 2 : 3 })}
      </Tag>
      <STitle>{t("onboarding:personalData:title")}</STitle>
      <SText variant="h5">{t("onboarding:personalData:text")}</SText>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Controller
          name="title"
          control={control}
          render={({
            field,
            formState: {
              errors: { title },
            },
          }) => (
            <TextField
              type="text"
              inputProps={field}
              error={!!title?.message}
              helperText={title?.message}
              sx={{ mb: 4 }}
              placeholder={t("onboarding:personalData:inputFields:title:placeholder")}
            />
          )}
        />
        <Controller
          name="userFirstName"
          control={control}
          render={({
            field,
            formState: {
              errors: { userFirstName },
            },
          }) => (
            <TextField
              type="text"
              inputProps={field}
              error={!!userFirstName?.message}
              helperText={userFirstName?.message}
              sx={{ mb: 4 }}
              placeholder={t("onboarding:personalData:inputFields:userFirstName:placeholder")}
            />
          )}
        />
        <Controller
          name="userLastName"
          control={control}
          render={({
            field,
            formState: {
              errors: { userLastName },
            },
          }) => (
            <TextField
              type="text"
              inputProps={field}
              error={!!userLastName?.message}
              helperText={userLastName?.message}
              sx={{ mb: 4 }}
              placeholder={t("onboarding:personalData:inputFields:userLastName:placeholder")}
            />
          )}
        />
        <Controller
          name="userGender"
          control={control}
          render={({
            field: { onChange, ref, ...field },
            formState: {
              errors: { userGender },
            },
          }) => (
            <Autocomplete
              {...field}
              onChange={(_, data) => onChange(data)}
              sx={{ mb: 4 }}
              textFieldProps={{
                ...field,
                error: !!userGender?.message,
                helperText: userGender?.message,
                label: t("onboarding:personalData:inputFields:userGender:placeholder"),
                inputRef: ref,
              }}
              options={genderOptions}
            />
          )}
        />
        <Box sx={{ position: "relative" }}>
          <Controller
            name="userDateOfBirth"
            control={control}
            render={({
              field: { onChange, ref, ...field },
              formState: {
                errors: { userDateOfBirth },
              },
            }) => (
              <DataPicker
                {...field}
                onChange={onChange}
                textFieldProps={{
                  ...field,
                  sx: { marginBottom: 4 },
                  error: !!userDateOfBirth?.message,
                  helperText: userDateOfBirth?.message,
                  label: t("onboarding:personalData:inputFields:userDateOfBirth:placeholder"),
                  inputRef: ref,
                  autoComplete: "off",
                }}
              />
            )}
          />
        </Box>
        <Controller
          name="userLocale"
          control={control}
          render={({
            field: { onChange, ref, ...field },
            formState: {
              errors: { userLocale },
            },
          }) => (
            <Autocomplete
              {...field}
              onChange={(_, data) => onChange(data)}
              sx={{ mb: 4 }}
              textFieldProps={{
                ...field,
                error: !!userLocale?.message,
                helperText: userLocale?.message,
                label: t("onboarding:personalData:inputFields:userLocale:placeholder"),
                inputRef: ref,
              }}
              options={languagesArray}
            />
          )}
        />
        <Button
          type="submit"
          disabled={!!Object.keys(formState.errors).length || isLoading}
          color="secondary"
          fullWidth
          variant="contained"
        >
          {t("onboarding:personalData:submitButtonText")}
        </Button>
      </form>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={error}
        onClose={handleClose}
      >
        <Alert severity="error" sx={{ width: "100%" }} onClose={handleClose} closeText="Close">
          {error}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default PersonalData
