import { useCallback, useEffect, useRef, useState } from "react"
import { useMutation } from "@tanstack/react-query"
// services
import { verifyEmail } from "api/auth"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
import { fancyTimeFormat } from "utils/time"

const RESEND_TIME_IN_SECONDS = 120

const useVerifyEmail = () => {
  const [error, setError] = useState()
  const [time, setTime] = useState(Date.now())
  const [expiredTime, setExpiredTime] = useState(RESEND_TIME_IN_SECONDS)

  const resendIntervalRef = useRef<ReturnType<typeof setInterval>>()

  const { user } = useAuthenticationContext()

  const {
    mutate: _verifyEmail,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: verifyEmail,
    onError: (err: any) => {
      setError(err?.response?.data?.error?.message)
    },
  })

  const resentConfirmationEmail = useCallback(() => {
    const passedTime = +((Date.now() - time) / 1000).toFixed(0)

    if (passedTime <= RESEND_TIME_IN_SECONDS) {
      setExpiredTime(RESEND_TIME_IN_SECONDS - passedTime)
    }
  }, [time])

  useEffect(() => {
    if (resendIntervalRef.current) clearInterval(resendIntervalRef.current)

    resendIntervalRef.current = setInterval(resentConfirmationEmail, 1000)
  }, [resendIntervalRef, resentConfirmationEmail])

  useEffect(() => {
    return () => {
      if (resendIntervalRef.current) {
        clearInterval(resendIntervalRef.current)
      }
    }
  }, [resendIntervalRef])

  const handleResendVerifyEmail = useCallback(
    (e: any) => {
      e.preventDefault()
      verifyEmail({ email: user.email })
      setExpiredTime(RESEND_TIME_IN_SECONDS)
      setTime(Date.now())
    },
    [verifyEmail, user.email]
  )

  return {
    verifyEmail: _verifyEmail,
    isLoading,
    isError,
    error,
    expiredTime: fancyTimeFormat(expiredTime),
    expiredTimeSeconds: expiredTime,
    handleResendVerifyEmail,
  }
}

export default useVerifyEmail
