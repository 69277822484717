import { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
// context
import useVideoLanguageContext from "context/VideoLanguage/useVideoLanguageContext"
// constants
import { languagesTransformer } from "i18n"
// types
import { EpisodeType, VLanguage } from "../useCourse/types"

type Props = {
  languages: VLanguage[]
  currentEpisode: EpisodeType
}

const BASE_LANGUAGE = {
  localizationKey: "en",
  videoLanguage: "English",
}

export const useChangeVideoLanguage = ({ languages, currentEpisode }: Props) => {
  const { i18n } = useTranslation()
  const [change, setChange] = useState(false)
  const [currentLanguage, setCurrentLanguage] = useState<VLanguage>(BASE_LANGUAGE)

  const { changedEntryPlayerId, setEntryPlayerId, setEpisodeId } = useVideoLanguageContext()
  const timeoutRef = useRef<any>()

  const reinitializePlayer = useCallback(() => {
    setChange(true)
    timeoutRef.current = setTimeout(() => {
      setChange(false)
    }, 50)
  }, [])

  const handleChangeVideoLanguage = useCallback(
    (lang: VLanguage) => {
      const videoInAnotherLanguage = currentEpisode.localizations?.find((localization) =>
        localization.attributes.locale.startsWith(lang.localizationKey)
      )

      setEntryPlayerId(videoInAnotherLanguage?.attributes.kalturaVideoId || "")
      setEpisodeId(videoInAnotherLanguage?.id || 0)
      setCurrentLanguage(lang)
      reinitializePlayer()
    },
    [currentEpisode, reinitializePlayer]
  )

  useEffect(() => {
    setCurrentLanguage(
      languages.find((lang) => lang.localizationKey?.startsWith(i18n.language)) || {
        // TODO: temporary fix for german palform  where we have only an english video languages
        localizationKey: languagesTransformer[i18n.language as keyof typeof languagesTransformer],
        videoLanguage: languages[0]?.videoLanguage,
      }
    )
  }, [languages])

  useEffect(() => {
    setEntryPlayerId("")
    setEpisodeId(0)

    handleChangeVideoLanguage(
      languages.find((lang) => lang.localizationKey?.startsWith(i18n.language)) || {
        // TODO: temporary fix for german palform  where we have only an english video languages
        localizationKey: languagesTransformer[i18n.language as keyof typeof languagesTransformer],
        videoLanguage: languages[0]?.videoLanguage,
      }
    )

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [currentEpisode, timeoutRef, handleChangeVideoLanguage])

  return { handleChangeVideoLanguage, currentLanguage, changedEntryPlayerId, change }
}
